import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
    completionPercentage:0
}

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        },
        completion:(state,action) =>{
          state.completionPercentage=action.payload.completion
        }
    },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, completion } = counterSlice.actions;

export default counterSlice.reducer