import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { addNewShareCapital, getAISbalance } from "../../../../Utils/API_Axios";

function ShareCapital({ clientForm,setCurrentTab }) {
  // console.log('clicnt', clientForm)
  const [_isInitalSame, setIsInitalSame] = useState(false);
  const [dataObjects, setDataObjects] = useState([]);
  const [allDif, setAllDif] = useState([]);
  const [isAllDiffrent, setIsAllDiffrent] = useState(false);
  const [_formData, setFormData] = useState({});
  const [_initialData, setInitialData] = useState({
    authorised: undefined,
    issued: undefined,
    subscribed: undefined,
  });

  const [inputList1, setInputList1] = useState([
    { entity: "", amount: "", totalShares: "" },
  ]);
  const [inputList2, setInputList2] = useState([
    { entity: "", amount: "", totalShares: "" },
  ]);
  const [inputList3, setInputList3] = useState([
    { name: "", equity: "", py: "", cy: "" },
  ]);

  const [_a, set_a] = useState([{ entity: "", amount: "", totalShares: "" }]);
  const [_b, set_b] = useState([{ entity: "", amount: "", totalShares: "" }]);
  const [_c, set_c] = useState([{ name: "", equity: "", totalShares: "" }]);
  const [_d, set_d] = useState([{ entity: "", amount: "", totalShares: "" }]);
  const [_e, set_e] = useState([{ entity: "", amount: "", totalShares: "" }]);
  const [_f, set_f] = useState([{ name: "", equity: "", totalShares: "" }]);

  const [otherHeadings, setOtherHeading] = useState("Breakup of Share Capital");

  useEffect(() => {
    getAIS(clientForm.company_id, clientForm.fy);
  }, []);

  const getAIS = async (company_id, fy) => {
    let user_id = JSON.parse(localStorage.getItem("userDetails"))._id;
    const response = await getAISbalance({
      //User_ID: user_id,
      Company_ID: company_id || clientForm.company_id,
      fy_year: fy || clientForm.fy,
    });

    if (response?.data != null) {
      setInitialData({
        authorised: response?.data?.closing_balance,
        issued: response?.data?.closing_balance,
        subscribed: response?.data?.closing_balance,
      });
    } else {
      console.log("Error");
    }
  };

  // handle input change
  const handleInputChange = (e, index, id) => {
    const { name, value } = e.target;
    switch (id) {
      case 1:
        const list1 = [...inputList1];
        list1[index][name] = value;
        setInputList1(list1);
        break;

      case 2:
        const list2 = [...inputList2];
        list2[index][name] = value;
        setInputList1(list2);
        break;
      case 3:
        const list3 = [...inputList3];
        list3[index][name] = value;
        setInputList1(list3);
        break;
      default:
        break;
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index, id) => {
    switch (id) {
      case 1:
        const list1 = [...inputList1];
        list1.splice(index, 1);
        setInputList1(list1);
        break;

      case 2:
        const list2 = [...inputList2];
        list2.splice(index, 1);
        setInputList2(list2);
        break;
      case 3:
        const list3 = [...inputList3];
        list3.splice(index, 1);
        setInputList3(list3);
        break;
      case "a":
        const lista = [..._a];
        lista.splice(index, 1);
        set_a(lista);
        break;

      case "b":
        const listb = [..._b];
        listb.splice(index, 1);
        set_b(listb);
        break;
      case "c":
        const listc = [..._c];
        listc.splice(index, 1);
        set_c(listc);
        break;
      case "d":
        const listd = [..._d];
        lista.splice(index, 1);
        set_d(listd);
        break;

      case "e":
        const liste = [..._e];
        liste.splice(index, 1);
        set_e(liste);
        break;
      case "f":
        const listf = [..._f];
        listf.splice(index, 1);
        set_f(listf);
        break;
      default:
        break;
    }
  };

  // handle click event of the Add button
  const handleAddClick = (id) => {
    switch (id) {
      case 1:
        setInputList1([
          ...inputList1,
          { entity: "", amount: "", totalShares: "" },
        ]);
        break;

      case 2:
        setInputList2([
          ...inputList2,
          { entity: "", amount: "", totalShares: "" },
        ]);
        break;
      case 3:
        setInputList3([
          ...inputList3,
          { name: "", equity: "", totalShares: "" },
        ]);
        break;
      case "a":
        set_a([..._a, { entity: "", amount: "", totalShares: "" }]);
        break;

      case "b":
        set_b([..._b, { entity: "", amount: "", totalShares: "" }]);
        break;
      case "c":
        set_c([..._c, { name: "", equity: "", totalShares: "" }]);
        break;
      case "d":
        set_d([..._d, { entity: "", amount: "", totalShares: "" }]);
        break;

      case "e":
        set_e([..._e, { entity: "", amount: "", totalShares: "" }]);
        break;
      case "f":
        set_f([..._f, { name: "", equity: "", totalShares: "" }]);
        break;
      default:
        break;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log(_formData);
    let payload = _formData;
    payload.company_id = clientForm.company_id;
    payload.FY = clientForm.fy;
    payload.userId = clientForm.user_id;

    // console.log(_initialData);
    const response = await addNewShareCapital(payload);
    if(response?.data?.errorStatus == 200)  setCurrentTab(1);

  };

  const handleFormChange = (e) => {
    setFormData({ ..._formData, [e.target.name]: e.target.value });
  };

  const handleInitalChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ..._initialData, [name]: value };

    setInitialData(updatedData);
  };

  useEffect(() => {
    // Check for differences among all three fields
    const authorisedSameAsIssued =
      _initialData.authorised === _initialData.issued;
    const authorisedSameAsSubscribed =
      _initialData.authorised === _initialData.subscribed;
    const issuedSameAsSubscribed =
      _initialData.issued === _initialData.subscribed;

    // Create objects based on differences
    const objects = [];
    setIsAllDiffrent(false);
    setAllDif([]);
    setDataObjects(objects);

    let _otherHeading = "Breakup of Share Capital";
    if (
      !authorisedSameAsIssued &&
      !authorisedSameAsSubscribed &&
      !issuedSameAsSubscribed
    ) {
      setIsAllDiffrent(true);

      // All values are different
      // bspc
      objects.push(
        {
          type: "Break up of Subscribed & Paid-up Capital",
          data: { authorised: _initialData.authorised },
          component: (
            <>
              {" "}
              <div className="tab_items">
                <span className="span_other">
                  Break up of Subscribed & Paid-up Capital
                </span>
              </div>
              <div className="mt-4 inputs_share_container">
                {_c.map((x, i) => {
                  return (
                    <div className="box inputs_share">
                      <div className="btn_box">
                        <div className="input_elements">
                          {_c.length - 1 === i && (
                            <>
                              <Button
                                sx={
                                  _c.length <= 1
                                    ? {
                                        marginRight: "72px",
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                    : {
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                }
                                onClick={() => handleAddClick("c")}
                              >
                                <AddCircleIcon sx={{ fontSize: "22px" }} />
                              </Button>
                            </>
                          )}
                          {_c.length !== 1 && (
                            <Button
                              disableTouchRipple
                              sx={
                                _c.length - 2 >= i
                                  ? {
                                      marginRight: "72px",
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                                  : {
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                              }
                              className="mr10"
                              onClick={() => handleRemoveClick(i, "c")}
                            >
                              <RemoveCircleIcon sx={{ fontSize: "22px" }} />
                            </Button>
                          )}
                          <span>{i + 1}.</span>
                          {/* <TextField
                    sx={{ padding: "0px !importtant" }}
                    name="entity"
                    placeholder="Equity Shares"
                    value={x.entity}
                    onChange={(e) => handleInputChange(e, i, 1)}
                  /> */}
                          <FormControl size="small" className="width-40">
                            <InputLabel
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              Select Share Type
                            </InputLabel>
                            <Select
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                              name={`bspc_select[${i}]`}
                              required
                              value={_formData[`bspc_select[${i}]`] || ""}
                              label="Select Share Type"
                              onChange={handleFormChange}
                            >
                              <MenuItem value="">
                                <em>Select Share Type</em>
                              </MenuItem>
                              <MenuItem value="equity">Equity</MenuItem>
                              <MenuItem value="preference">
                                Preferences
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <span>of Rs.</span>
                          <TextField
                            className="ml10"
                            name={`bspc_amount[${i}]`}
                            type="number"
                            value={_formData.bspc_amount}
                            sx={{
                              fontSize: "14px",
                              padding:"10px !important",
                              "&.MuiOutlinedInput-notchedOutline": {
                                fontSize: "14px",
                              },
                            }}
                            //onChange={(e) => handleInputChange(e, i, 1)}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>/Shares of</span>
                          <TextField
                            className="ml10"
                            name={`bspc_totalShares[${i}]`}
                            type="number"
                            value={_formData.bspc_totalShares}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>No. of Shares(Qty) in Total Rs. 1,00,000</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ),
        },
        {
          type: "Break up of Issued Capital",
          data: { issued: _initialData.issued },
          component: (
            <>
              {" "}
              <div className="tab_items">
                <span className="span_other">Break up of Issued Capital</span>
              </div>
              <div className="mt-4 inputs_share_container">
                {_b.map((x, i) => {
                  return (
                    <div className="box inputs_share">
                      <div className="btn_box">
                        <div className="input_elements">
                          {_b.length - 1 === i && (
                            <>
                              <Button
                                sx={
                                  _b.length <= 1
                                    ? {
                                        marginRight: "72px",
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                    : {
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                }
                                onClick={() => handleAddClick("b")}
                              >
                                <AddCircleIcon sx={{ fontSize: "22px" }} />
                              </Button>
                            </>
                          )}
                          {_b.length !== 1 && (
                            <Button
                              disableTouchRipple
                              sx={
                                _b.length - 2 >= i
                                  ? {
                                      marginRight: "72px",
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                                  : {
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                              }
                              className="mr10"
                              onClick={() => handleRemoveClick(i, "b")}
                            >
                              <RemoveCircleIcon sx={{ fontSize: "22px" }} />
                            </Button>
                          )}
                          <span>{i + 1}.</span>
                          {/* <TextField
                    sx={{ padding: "0px !importtant" }}
                    name="entity"
                    placeholder="Equity Shares"
                    value={x.entity}
                    onChange={(e) => handleInputChange(e, i, 1)}
                  /> */}
                          <FormControl size="small" className="width-40">
                            <InputLabel
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              Select Share Type
                            </InputLabel>
                            <Select
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                              name={`bic_select[${i}]`}
                              required
                              value={_formData[`bic_select[${i}]`] || ""}
                              label="Select Share Type"
                              onChange={handleFormChange}
                            >
                              <MenuItem value="">
                                <em>Select Share Type</em>
                              </MenuItem>
                              <MenuItem value="equity">Equity</MenuItem>
                              <MenuItem value="preference">
                                Preferences
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <span>of Rs.</span>
                          <TextField
                            className="ml10"
                            name={`bic_amount[${i}]`}
                            type="number"
                            value={_formData.bic_amount}
                            //onChange={(e) => handleInputChange(e, i, 1)}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>/Shares of</span>
                          <TextField
                            className="ml10"
                            name={`bic_totalShares[${i}]`}
                            type="number"
                            value={_formData.bic_totalShares}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>No. of Shares(Qty) in Total Rs. 1,00,000</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ),
        },
        {
          type: "Break up of Authorised",
          data: { subscribed: _initialData.subscribed },
          component: (
            <>
              {" "}
              <div className="tab_items">
                <span className="span_other">Break up of Authorised</span>
              </div>
              <div className="mt-4 inputs_share_container">
                {_a.map((x, i) => {
                  return (
                    <div className="box inputs_share">
                      <div className="btn_box">
                        <div className="input_elements">
                          {_a.length - 1 === i && (
                            <>
                              <Button
                                sx={
                                  _a.length <= 1
                                    ? {
                                        marginRight: "72px",
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                    : {
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                }
                                onClick={() => handleAddClick("a")}
                              >
                                <AddCircleIcon sx={{ fontSize: "22px" }} />
                              </Button>
                            </>
                          )}
                          {_a.length !== 1 && (
                            <Button
                              disableTouchRipple
                              sx={
                                _a.length - 2 >= i
                                  ? {
                                      marginRight: "72px",
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                                  : {
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                              }
                              className="mr10"
                              onClick={() => handleRemoveClick(i, "a")}
                            >
                              <RemoveCircleIcon sx={{ fontSize: "22px" }} />
                            </Button>
                          )}
                          <span>{i + 1}.</span>
                          {/* <TextField
                    sx={{ padding: "0px !importtant" }}
                    name="entity"
                    placeholder="Equity Shares"
                    value={x.entity}
                    onChange={(e) => handleInputChange(e, i, 1)}
                  /> */}
                          <FormControl size="small" className="width-40">
                            <InputLabel
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              Select Share Type
                            </InputLabel>
                            <Select
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                              name={`bac_select[${i}]`}
                              required
                              value={_formData[`bac_select[${i}]`] || ""}
                              label="Select Share Type"
                              onChange={handleFormChange}
                            >
                              <MenuItem value="">
                                <em>Select Share Type</em>
                              </MenuItem>
                              <MenuItem value="equity">Equity</MenuItem>
                              <MenuItem value="preference">
                                Preferences
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <span>of Rs.</span>
                          <TextField
                            className="ml10"
                            name={`bac_amount[${i}]`}
                            type="number"
                            value={_formData.bac_amount}
                            //onChange={(e) => handleInputChange(e, i, 1)}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>/Shares of</span>
                          <TextField
                            className="ml10"
                            name={`bac_totalShares[${i}]`}
                            type="number"
                            value={_formData.bac_totalShares}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>No. of Shares(Qty) in Total Rs. 1,00,000</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ),
        }
      );
    } else if (authorisedSameAsIssued && !authorisedSameAsSubscribed) {
      // Only authorised and issued are the same, subscribed is different
      _otherHeading = "Break up of Authorised & Issued Capital";
      objects.push({
        type: "Break up of Subscribed & Paid-up Capital",
        data: {
          authorised: _initialData.authorised,
          subscribed: _initialData.subscribed,
          component: (
            <>
              {" "}
              <div className="tab_items">
                <span className="span_other">
                  Break up of Subscribed & Paid-up Capital
                </span>
              </div>
              <div className="mt-4 inputs_share_container">
                {_d.map((x, i) => {
                  return (
                    <div className="box inputs_share">
                      <div className="btn_box">
                        <div className="input_elements">
                          {_d.length - 1 === i && (
                            <>
                              <Button
                                sx={
                                  _d.length <= 1
                                    ? {
                                        marginRight: "72px",
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                    : {
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                }
                                onClick={() => handleAddClick("d")}
                              >
                                <AddCircleIcon sx={{ fontSize: "22px" }} />
                              </Button>
                            </>
                          )}
                          {_d.length !== 1 && (
                            <Button
                              disableTouchRipple
                              sx={
                                _d.length - 2 >= i
                                  ? {
                                      marginRight: "72px",
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                                  : {
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                              }
                              className="mr10"
                              onClick={() => handleRemoveClick(i, "d")}
                            >
                              <RemoveCircleIcon sx={{ fontSize: "22px" }} />
                            </Button>
                          )}
                          <span>{i + 1}.</span>
                          {/* <TextField
                    sx={{ padding: "0px !importtant" }}
                    name="entity"
                    placeholder="Equity Shares"
                    value={x.entity}
                    onChange={(e) => handleInputChange(e, i, 1)}
                  /> */}
                          <FormControl size="small" className="width-40">
                            <InputLabel
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              Select Share Type
                            </InputLabel>
                            <Select
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                              name={`bspc_select[${i}]`}
                              required
                              value={_formData[`bspc_select[${i}]`] || ""}
                              label="Select Share Type"
                              onChange={handleFormChange}
                            >
                              <MenuItem value="">
                                <em>Select Share Type</em>
                              </MenuItem>
                              <MenuItem value="equity">Equity</MenuItem>
                              <MenuItem value="preference">
                                Preferences
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <span>of Rs.</span>
                          <TextField
                            className="ml10"
                            name={`bspc_amount[${i}]`}
                            type="number"
                            value={_formData.bspc_amount}
                            //onChange={(e) => handleInputChange(e, i, 1)}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>/Shares of</span>
                          <TextField
                            className="ml10"
                            name={`bspc_totalShares[${i}]`}
                            type="number"
                            value={_formData.bspc_totalShares}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>No. of Shares(Qty) in Total Rs. 1,00,000</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ),
        },
      });
    } else if (authorisedSameAsSubscribed && !authorisedSameAsIssued) {
      // Only authorised and subscribed are the same, issued is different
      _otherHeading = "Break up of Authorised & Subscribed Capital";
      objects.push({
        type: "Break up of Issued Capital",

        data: {
          authorised: _initialData.authorised,
          issued: _initialData.issued,
          component: (
            <>
              {" "}
              <div className="tab_items">
                <span className="span_other">Break up of Issued Capital</span>
              </div>
              <div className="mt-4 inputs_share_container">
                {_e.map((x, i) => {
                  return (
                    <div className="box inputs_share">
                      <div className="btn_box">
                        <div className="input_elements">
                          {_e.length - 1 === i && (
                            <>
                              <Button
                                sx={
                                  _e.length <= 1
                                    ? {
                                        marginRight: "72px",
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                    : {
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                }
                                onClick={() => handleAddClick("e")}
                              >
                                <AddCircleIcon sx={{ fontSize: "22px" }} />
                              </Button>
                            </>
                          )}
                          {_e.length !== 1 && (
                            <Button
                              disableTouchRipple
                              sx={
                                _e.length - 2 >= i
                                  ? {
                                      marginRight: "72px",
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                                  : {
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                              }
                              className="mr10"
                              onClick={() => handleRemoveClick(i, "e")}
                            >
                              <RemoveCircleIcon sx={{ fontSize: "22px" }} />
                            </Button>
                          )}
                          <span>{i + 1}.</span>
                          {/* <TextField
                    sx={{ padding: "0px !importtant" }}
                    name="entity"
                    placeholder="Equity Shares"
                    value={x.entity}
                    onChange={(e) => handleInputChange(e, i, 1)}
                  /> */}
                          <FormControl size="small" className="width-40">
                            <InputLabel
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              Select Share Type
                            </InputLabel>
                            <Select
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                              name={`basc_select[${i}]`}
                              required
                              value={_formData[`basc_select[${i}]`] || ""}
                              label="Select Share Type"
                              onChange={handleFormChange}
                            >
                              <MenuItem value="">
                                <em>Select Share Type</em>
                              </MenuItem>
                              <MenuItem value="equity">Equity</MenuItem>
                              <MenuItem value="preference">
                                Preferences
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <span>of Rs.</span>
                          <TextField
                            className="ml10"
                            name={`basc_amount[${i}]`}
                            type="number"
                            value={_formData.basc_amount}
                            //onChange={(e) => handleInputChange(e, i, 1)}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>/Shares of</span>
                          <TextField
                            className="ml10"
                            name={`basc_totalShares[${i}]`}
                            type="number"
                            value={_formData.basc_totalShares}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>No. of Shares(Qty) in Total Rs. 1,00,000</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ),
        },
      });
    } else if (issuedSameAsSubscribed && !authorisedSameAsIssued) {
      // Only issued and subscribed are the same, authorised is different
      _otherHeading = "Break up of Issued & Subscribed Capital";
      objects.push({
        type: "Break up of Authorised",
        data: {
          authorised: _initialData.authorised,
          issued: _initialData.issued,
          component: (
            <>
              {" "}
              <div className="tab_items">
                <span className="span_other">Break up of Authorised</span>
              </div>
              <div className="mt-4 inputs_share_container">
                {_f.map((x, i) => {
                  return (
                    <div className="box inputs_share">
                      <div className="btn_box">
                        <div className="input_elements">
                          {_f.length - 1 === i && (
                            <>
                              <Button
                                sx={
                                  _f.length <= 1
                                    ? {
                                        marginRight: "72px",
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                    : {
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                }
                                onClick={() => handleAddClick("f")}
                              >
                                <AddCircleIcon sx={{ fontSize: "22px" }} />
                              </Button>
                            </>
                          )}
                          {_f.length !== 1 && (
                            <Button
                              disableTouchRipple
                              sx={
                                _f.length - 2 >= i
                                  ? {
                                      marginRight: "72px",
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                                  : {
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                              }
                              className="mr10"
                              onClick={() => handleRemoveClick(i, "f")}
                            >
                              <RemoveCircleIcon sx={{ fontSize: "22px" }} />
                            </Button>
                          )}
                          <span>{i + 1}.</span>
                          {/* <TextField
                    sx={{ padding: "0px !importtant" }}
                    name="entity"
                    placeholder="Equity Shares"
                    value={x.entity}
                    onChange={(e) => handleInputChange(e, i, 1)}
                  /> */}
                          <FormControl size="small" className="width-40">
                            <InputLabel
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              Select Share Type
                            </InputLabel>
                            <Select
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                              name={`bisc_select[${i}]`}
                              required
                              value={_formData[`bisc_select[${i}]`] || ""}
                              label="Select Share Type"
                              onChange={handleFormChange}
                            >
                              <MenuItem value="">
                                <em>Select Share Type</em>
                              </MenuItem>
                              <MenuItem value="equity">Equity</MenuItem>
                              <MenuItem value="preference">
                                Preferences
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <span>of Rs.</span>
                          <TextField
                            className="ml10"
                            name={`bisc_amount[${i}]`}
                            type="number"
                            value={_formData.bisc_amount}
                            //onChange={(e) => handleInputChange(e, i, 1)}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>/Shares of</span>
                          <TextField
                            className="ml10"
                            name={`bisc_totalShares[${i}]`}
                            type="number"
                            value={_formData.bisc_totalShares}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>No. of Shares(Qty) in Total Rs. 1,00,000</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ),
        },
      });
    }

    setOtherHeading(_otherHeading);
    setDataObjects(objects);
  }, [
    _initialData,
    _a,
    _b,
    _c,
    _d,
    _e,
    _f,
    setIsAllDiffrent,
    handleFormChange,
  ]);

  useEffect(() => {
    setAllDif(dataObjects);
  }, [setIsAllDiffrent, dataObjects]);

  return (
    <div className="mt-4 p-0 d-flex flex-column gap-10">
      {/* {dataObjects} */}

      <div className="warning">
        <p>
          <span style={{ color: "red" }}>Note:</span> Information is
          Automatically Filled, you can update the information wherever
          required.
        </p>
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="share_capital">
          <div className="tab_items">
            <span className="span_other">Type of Share Capital</span>
          </div>
          <div className="share_capital_items mt-4">
            <Table sx={{ width: "40vw" }}>
              <TableBody>
                <TableRow>
                  <TableCell>a) Authorised</TableCell>
                  <TableCell>
                    <TextField
                      onChange={(e) => handleInitalChange(e)}
                      name="authorised"
                      value={_initialData.authorised}
                      type="number"
                    ></TextField>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>b) Issued</TableCell>
                  <TableCell>
                    <TextField
                      onChange={(e) => handleInitalChange(e)}
                      // name="issued"
                      //value={_initialData.issued}
                      value={_initialData.authorised}
                      type="readonly"
                      // disabled
                    ></TextField>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>c) Subscribed & Paid-up</TableCell>
                  <TableCell>
                    <TextField
                      onChange={(e) => handleInitalChange(e)}
                      //name="subscribed"
                      //value={_initialData.subscribed}
                      value={_initialData.authorised}
                      type="readonly"
                      // disabled
                    ></TextField>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className="lineBreak" />

          {!isAllDiffrent && (
            <>
              <div className="tab_items">
                {/* <span className="span_other">{otherHeadings}</span> */}
                <span className="span_other">Breakup of Share Capital</span>
              </div>
              <div className="mt-4 inputs_share_container">
                {inputList1.map((x, i) => {
                  return (
                    <div className="box inputs_share">
                      <div className="btn_box">
                        <div className="input_elements">
                          {inputList1.length - 1 === i && (
                            <>
                              <Button
                                sx={
                                  inputList1.length <= 1
                                    ? {
                                        marginRight: "72px",
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                    : {
                                        color: "#2DA2FB",
                                        ":hover": { backgroundColor: "white" },
                                      }
                                }
                                onClick={() => handleAddClick(1)}
                              >
                                <AddCircleIcon sx={{ fontSize: "22px" }} />
                              </Button>
                            </>
                          )}
                          {inputList1.length !== 1 && (
                            <Button
                              disableTouchRipple
                              sx={
                                inputList1.length - 2 >= i
                                  ? {
                                      marginRight: "72px",
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                                  : {
                                      color: "#2DA2FB",
                                      ":hover": { backgroundColor: "white" },
                                    }
                              }
                              className="mr10"
                              onClick={() => handleRemoveClick(i, 1)}
                            >
                              <RemoveCircleIcon sx={{ fontSize: "22px" }} />
                            </Button>
                          )}
                          <span>{i + 1}.</span>
                          {/* <TextField
                      sx={{ padding: "0px !importtant" }}
                      name="entity"
                      placeholder="Equity Shares"
                      value={x.entity}
                      onChange={(e) => handleInputChange(e, i, 1)}
                    /> */}
                          <FormControl size="small" className="width-40">
                            <InputLabel
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              Select Share Type
                            </InputLabel>
                            <Select
                              sx={{
                                fontSize: "14px",
                                "&.MuiOutlinedInput-notchedOutline": {
                                  fontSize: "14px",
                                },
                              }}
                              name={`share_capital_select[${i}]`}
                              required
                              value={
                                _formData[`share_capital_select[${i}]`] || ""
                              }
                              label="Select Share Type"
                              onChange={handleFormChange}
                            >
                              <MenuItem value="">
                                <em>Select Share Type</em>
                              </MenuItem>
                              <MenuItem value="equity">Equity</MenuItem>
                              <MenuItem value="preference">
                                Preferences
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <span>of Rs.</span>
                          <TextField
                            className="ml10"
                            name={`share_capital_amount[${i}]`}
                            type="number"
                            value={_formData.share_capital_amount}
                            //onChange={(e) => handleInputChange(e, i, 1)}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>/Shares of</span>
                          <TextField
                            className="ml10"
                            name={`share_capital_totalShares[${i}]`}
                            type="number"
                            value={_formData.share_capital_totalShares}
                            onChange={(e) => handleFormChange(e)}
                          />
                          <span>No. of Shares(Qty) in Total Rs. 1,00,000</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
{/* 
          {dataObjects.map((_item) => {
            return _item.data.component;
          })}
          {allDif.map((_item) => {
            return _item.component;
          })} */}

          <div className="lineBreak" />
          <div className="tab_items">
            <span className="span_other">Breakup of Fresh Issue</span>
          </div>
          <div className="mt-4 inputs_share_container">
            {inputList2.map((x, i) => {
              return (
                <div className="box inputs_share">
                  <div className="btn_box">
                    <div className="input_elements">
                      {inputList2.length - 1 === i && (
                        <Button
                          sx={
                            inputList2.length <= 1
                              ? {
                                  marginRight: "72px",
                                  color: "#2DA2FB",
                                  ":hover": { backgroundColor: "white" },
                                }
                              : {
                                  color: "#2DA2FB",
                                  ":hover": { backgroundColor: "white" },
                                }
                          }
                          onClick={() => handleAddClick(2)}
                        >
                          <AddCircleIcon sx={{ fontSize: "22px" }} />
                        </Button>
                      )}
                      {inputList2.length !== 1 && (
                        <Button
                          disableTouchRipple
                          sx={
                            inputList2.length - 2 >= i
                              ? {
                                  marginRight: "72px",
                                  color: "#2DA2FB",
                                  ":hover": { backgroundColor: "white" },
                                }
                              : {
                                  color: "#2DA2FB",
                                  ":hover": { backgroundColor: "white" },
                                }
                          }
                          className="mr10"
                          onClick={() => handleRemoveClick(i, 2)}
                        >
                          <RemoveCircleIcon sx={{ fontSize: "22px" }} />
                        </Button>
                      )}
                      <span>{i + 1}.</span>
                      <FormControl size="small" className="width-40">
                        <InputLabel
                          sx={{
                            fontSize: "14px",
                            "&.MuiOutlinedInput-notchedOutline": {
                              fontSize: "14px",
                            },
                          }}
                        >
                          Select Share Type
                        </InputLabel>
                        <Select
                          sx={{
                            fontSize: "14px",
                            "&.MuiOutlinedInput-notchedOutline": {
                              fontSize: "14px",
                            },
                          }}
                          name={`fresh_share_select[${i}]`}
                          required
                          value={_formData[`fresh_share_select[${i}]`] || ""}
                          label="Select Share Type"
                          onChange={handleFormChange}
                        >
                          <MenuItem
                            sx={{
                              fontSize: "14px",
                              "&.MuiOutlinedInput-notchedOutline": {
                                fontSize: "14px",
                              },
                            }}
                            value=""
                          >
                            <em>Select Share Type</em>
                          </MenuItem>

                          <MenuItem
                            sx={{
                              fontSize: "14px",
                              "&.MuiOutlinedInput-notchedOutline": {
                                fontSize: "14px",
                              },
                            }}
                            value="equity"
                          >
                            Equity
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontSize: "14px",
                              "&.MuiOutlinedInput-notchedOutline": {
                                fontSize: "14px",
                              },
                            }}
                            value="preference"
                          >
                            Prefrences
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <span>of Rs.</span>
                      <TextField
                        className="ml10"
                        name={`fresh_share_amount[${i}]`}
                        type="number"
                        value={_formData.fresh_share_amount}
                        onChange={(e) => handleFormChange(e)}
                      />
                      <span>/Shares of</span>
                      <TextField
                        className="ml10"
                        name={`fresh_share_totalShares[${i}]`}
                        type="number"
                        value={_formData.fresh_share_totalShares}
                        onChange={(e) => handleFormChange(e)}
                      />
                      <span>No. of Shares(Qty) in Total Rs. 1,00,000</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="lineBreak" />
          <div className="tab_items">
            <span className="span_other">Share Holder List</span>
          </div>
          <div className="mt-4 inputs_share_container">
            {inputList3.map((x, i) => {
              return (
                <div className="box inputs_share">
                  <div className="btn_box">
                    <div className="input_elements">
                      {inputList3.length - 1 === i && (
                        <Button
                          sx={
                            inputList3.length <= 1
                              ? {
                                  marginRight: "72px",
                                  color: "#2DA2FB",
                                  ":hover": { backgroundColor: "white" },
                                }
                              : {
                                  color: "#2DA2FB",
                                  ":hover": { backgroundColor: "white" },
                                }
                          }
                          onClick={() => handleAddClick(3)}
                        >
                          <AddCircleIcon sx={{ fontSize: "22px" }} />
                        </Button>
                      )}
                      {inputList3.length !== 1 && (
                        <Button
                          disableTouchRipple
                          sx={
                            inputList3.length - 2 >= i
                              ? {
                                  marginRight: "72px",
                                  color: "#2DA2FB",
                                  ":hover": { backgroundColor: "white" },
                                }
                              : {
                                  color: "#2DA2FB",
                                  ":hover": { backgroundColor: "white" },
                                }
                          }
                          className="mr10"
                          onClick={() => handleRemoveClick(i, 3)}
                        >
                          <RemoveCircleIcon sx={{ fontSize: "22px" }} />
                        </Button>
                      )}
                      <span>{i + 1}.</span>
                      <TextField
                        className="ml10"
                        sx={{width:"180px"}}
                        name={`share_holder_name[${i}]`}
                        placeholder="Share Holder Name"
                        value={_formData.share_holder_name}
                        onChange={(e) => handleFormChange(e)}
                      />
                      <FormControl size="small" className="width-40">
                        <InputLabel
                          sx={{
                            fontSize: "14px",
                            "&.MuiOutlinedInput-notchedOutline": {
                              fontSize: "14px",
                            },
                          }}
                        >
                          Select Share Type
                        </InputLabel>
                        <Select
                          sx={{
                            fontSize: "14px",
                            "&.MuiOutlinedInput-notchedOutline": {
                              fontSize: "14px",
                            },
                          }}
                          name={`share_holder_select[${i}]`}
                          required
                          value={_formData[`share_holder_select[${i}]`] || ""}
                          label="Select Share Type"
                          onChange={handleFormChange}
                        >
                          <MenuItem
                            sx={{
                              fontSize: "14px",
                              "&.MuiOutlinedInput-notchedOutline": {
                                fontSize: "14px",
                              },
                            }}
                            value=""
                          >
                            <em>Select Share Type</em>
                          </MenuItem>

                          <MenuItem
                            sx={{
                              fontSize: "14px",
                              "&.MuiOutlinedInput-notchedOutline": {
                                fontSize: "14px",
                              },
                            }}
                            value="equity"
                          >
                            Equity
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontSize: "14px",
                              "&.MuiOutlinedInput-notchedOutline": {
                                fontSize: "14px",
                              },
                            }}
                            value="preference"
                          >
                            Prefrences
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        className="ml10"
                        name={`share_holder_py[${i}]`}
                        type="number"
                        placeholder="PY"
                        value={_formData.share_holder_py}
                        onChange={(e) => handleFormChange(e)}
                      />
                      <TextField
                        className="ml10"
                        name={`share_holder_cy[${i}]`}
                        type="number"
                        placeholder="CY"
                        value={_formData.share_holder_cy}
                        onChange={(e) => handleFormChange(e)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Button    
        sx={{ bgcolor: "#383b3f", width: "100px" }}
       variant="contained"
                  disableElevation
                  type="submit"
                >
                  Submit
                </Button>
      </form>
    </div>
  );
}

export default ShareCapital;
