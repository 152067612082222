import React, { useEffect, useState, useMemo, useCallback } from "react";
import { toast } from "react-toastify";
import { fetchWithTempUntagged } from "../../../Utils/API_Axios";
import { toIndianCommaFormat } from "../../../Utils/math";
import {
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  Box,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useFormData } from "./useFormData";
import {
  DefaultLedgerHeadDropDown1,
  DefaultLedgerHeadDropDown2,
  DefaultLedgerHeadDropDown3,
  DefaultLedgerHeadDropDown4,
  DefaultLedgerHeadDropDown5,
  DefaultLedgerHeadDropDown6,
  DefaultLedgerHeadMaster1,
  DefaultLedgerHeadMaster2,
  DefaultLedgerHeadMaster3,
  DefaultLedgerHeadMaster4,
  DefaultLedgerHeadMaster5,
  DefaultLedgerHeadMaster6,
} from "../../../Utils/DefaultLedgerHeadDropDown";

const masterDropdownMap = [
  { master: DefaultLedgerHeadMaster1, dropdown: DefaultLedgerHeadDropDown1 },
  { master: DefaultLedgerHeadMaster2, dropdown: DefaultLedgerHeadDropDown2 },
  { master: DefaultLedgerHeadMaster3, dropdown: DefaultLedgerHeadDropDown3 },
  { master: DefaultLedgerHeadMaster4, dropdown: DefaultLedgerHeadDropDown4 },
  { master: DefaultLedgerHeadMaster5, dropdown: DefaultLedgerHeadDropDown5 },
  { master: DefaultLedgerHeadMaster6, dropdown: DefaultLedgerHeadDropDown6 },
];
const cache = new Map();

const findDropdownOptions = (parent) => {
  if (cache.has(parent)) {
    return cache.get(parent);
  }

  for (let map of masterDropdownMap) {
    if (map.master.includes(parent)) {
      cache.set(parent, map.dropdown);
      return map.dropdown;
    }
  }

  cache.set(parent, null);
  return null;
};

const DropdownOrInput = React.memo(({ ledger, item, handleOnChange }) => {
  const dropdownOptions = useMemo(
    () => findDropdownOptions(ledger.primaryGroup),
    [ledger.primaryGroup]
  );

  const handleDropdownChange = useCallback(
    (e) => {
      handleOnChange(
        ledger.ledgerId,
        "head",
        e.target.value,
        ledger.opening_balance,
        ledger.debit,
        ledger.credit,
        ledger.closing_balance,
        ledger.name,
        item.name,
        ledger.masterHead
      );
    },
    [handleOnChange, ledger, item]
  );

  const handleInputBlur = useCallback(
    (e) => {
      handleOnChange(
        ledger.ledgerId,
        "head",
        e.target.value,
        ledger.opening_balance,
        ledger.debit,
        ledger.credit,
        ledger.closing_balance,
        ledger.name,
        item.name,
        ledger.masterHead
      );
    },
    [handleOnChange, ledger, item]
  );

  return dropdownOptions ? (
    <Autocomplete
      defaultValue={ledger.head}
      onChange={(event) => handleDropdownChange(event)}
      options={dropdownOptions}
      // getOptionLabel={(option) => option}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  ) : (
    <TextField
      defaultValue={ledger.child}
      fullWidth
      type="text"
      onBlur={handleInputBlur}
    />
  );
});
function flattenData(data, parent = null) {
  let result = [];
  data.forEach((item) => {
    if (item) {
      const newItem = {
        ...item,
        parentName: parent ? parent.name : null,
      };
      result.push(newItem);
      if (item.children && item.children.length > 0) {
        const children = flattenData(item.children, item);
        result = result.concat(children);
      }
    }
  });
  return result;
}

function AllTrail({ companyID,toggle }) {
  const [jsonData, setJsonData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const flattenedData = useMemo(() => flattenData(jsonData), [jsonData]);

  const checkAndHideParent = useCallback(
    (parentName) => {
      if (!parentName) return;
   
      const childrenOfParent = flattenedData.find(
        (item) => item.name === parentName
      );

      const allFilled = childrenOfParent?.ledger?.every((child) => {
        const formEntry = jsonData.find(
          (formItem) => formItem.id === child.ledgerId
        );
        return formEntry && formEntry.head && formEntry.child;
      });

      // if (allFilled) {
      //   setFilledChildren((prev) => ({ ...prev, [parentName]: true }));
      // }
    },
    [flattenedData, jsonData]
  );

   const [loading,setLoading]=useState(false)
   const [loadingIndex,setLoadingIndex]=useState(null)

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await fetchWithTempUntagged({ companyID });
      if (response?.data?.errorStatus === 200) {
        setJsonData(response?.data?.data);
      } else if (response?.data?.errorStatus === 401) {
        return toast.error(`Error: ${response?.data?.error}`);
      } else {
        return toast.error(
          `There is some issue on fetching Trail unTagged not found.`
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
    setLoading(false)

    }
  }, [companyID]);


  useEffect(() => {
    fetchData();
  }, [companyID,fetchData]);

  // Toggle row expansion
  const handleRowClick = (rowName) => {
    setLoadingIndex(rowName)
setTimeout(() => {
  setExpandedRows((prev) => ({ ...prev, [rowName]: !prev[rowName] }));
  setLoadingIndex(null)
}, 0);

  };

  useEffect(() => {
    if (toggle) {
      const expandedState = {};
      flattenedData.forEach(item => {
        if (item.name) {
          expandedState[item.name] = true;
        }
      });
      setExpandedRows(expandedState);
    } else {
      setExpandedRows({});
    }
  }, [toggle, flattenedData]);
  const { handleOnChange } = useFormData(
    jsonData,
    setJsonData,
    flattenedData,
    checkAndHideParent
  );

  const renderRows = (data, padding, level = 0) => {
    return data.map((item, index) => {
      const isParentRow = item?.name;

      return isParentRow ? (
        <React.Fragment key={index}>
          <TableRow
            sx={{
              cursor: "pointer",
              backgroundColor: "#f0f0f0",
              "&:hover": { backgroundColor: "#e0e0e0" },
            }}
          >
            <TableCell
              onClick={() => handleRowClick(item.name)}
              sx={
                level === 0
                  ? {
                      backgroundColor: "#2DA2FB",
                      color: "#fff",
                      padding: `2px 0px 2px 2px`,
                      cursor: "pointer",
                    }
                  : {
                      backgroundColor: "#2DA2FB",
                      color: "#fff",
                      padding: `0px 0px 0px ${padding + 10}px`,
                      cursor: "pointer",
                    }
              }
            >
              {level === 0 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{ color: "white", padding: "0px", margin: "0px" }}
                >
                  {expandedRows[item.name] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </IconButton>
              )}
              {item.name}
              {loadingIndex ===item.name &&
               <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
               open={loadingIndex === item.name}
             >
               <CircularProgress color="inherit" />
             </Backdrop>
              }
            </TableCell>
            {/* Additional cells for the parent row */}
            <TableCell
              sx={{
                backgroundColor: "#2DA2FB",
                color: "#fff",
                padding: "2px 2px 2px 10px ",
              }}
            ></TableCell>
            <TableCell
              sx={{
                backgroundColor: "#2DA2FB",
                color: "#fff",
                padding: "2px 2px 2px 10px ",
              }}
            ></TableCell>
            <TableCell
              sx={{
                backgroundColor: "#2DA2FB",
                color: "#fff",
                padding: "2px 2px 2px 10px ",
              }}
            ></TableCell>
            <TableCell
              sx={{
                backgroundColor: "#2DA2FB",
                color: "#fff",
                padding: "2px 2px 2px 10px ",
              }}
            ></TableCell>
            <TableCell
              sx={{
                backgroundColor: "#2DA2FB",
                color: "#fff",
                padding: "2px 2px 2px 10px ",
              }}
            ></TableCell>
            <TableCell
              sx={{
                backgroundColor: "#2DA2FB",
                color: "#fff",
                padding: "2px 2px 2px 10px ",
              }}
            ></TableCell>
          </TableRow>

          {level === 0 ? (
            <>
              {expandedRows[item.name] && (
                <>
                  {item.children &&
                    renderRows(item.children, padding + 10, level + 1)}
                  {item.ledger &&
                    item.ledger.map(
                      (ledger, i) =>
                        ((ledger.head && ledger.child) ||
                          (ledger.isTagged &&
                            !ledger.head &&
                            !ledger.child)) && (
                          <TableRow
                            key={`${index}-${i}`}
                            className="ledger_child"
                            sx={{
                              backgroundColor: "#fafafa",
                              "&:hover": { backgroundColor: "#f5f5f5" },
                            }}
                          >
                            <TableCell
                              sx={{
                                padding: `2px 2px 2px ${padding + 20}px`,
                              }}
                            >
                              {ledger.name}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "2px 2px 2px 4px " }}
                              className="numeric"
                            >
                              {toIndianCommaFormat(ledger.opening_balance) ||
                                ""}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "2px 2px 2px 4px " }}
                              className="numeric"
                            >
                              {toIndianCommaFormat(ledger.debit) || ""}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "2px 2px 2px 4px " }}
                              className="numeric"
                            >
                              {toIndianCommaFormat(ledger.credit) || ""}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "2px 2px 2px 4px " }}
                              className="numeric"
                            >
                              {toIndianCommaFormat(ledger.closing_balance) ||
                                ""}
                            </TableCell>
                            <TableCell sx={{ padding: "3px 2px 3px 4px" }}>
                              <DropdownOrInput
                                ledger={ledger}
                                item={item}
                                handleOnChange={handleOnChange}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: "3px 2px 3px 4px" }}>
                            <TextField
                                fullWidth
                                type="text"
                                defaultValue={ledger.child}
                                // value={ledger.child}
                                onBlur={(e) =>
                                  handleOnChange(
                                    ledger.ledgerId,
                                    "child",
                                    e.target.value,
                                    ledger.opening_balance,
                                    ledger.debit,
                                    ledger.credit,
                                    ledger.closing_balance,
                                    ledger.name,
                                    item.name,
                                    ledger.masterHead
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        )
                    )}
                </>
              )}
            </>
          ) : (
            <>
              {item.children &&
                renderRows(item.children, padding + 10, level + 1)}
              {item.ledger &&
                item.ledger.map(
                  (ledger, i) =>
                    ((ledger.head && ledger.child) ||
                      (ledger.isTagged && !ledger.head && !ledger.child)) && (
                      <TableRow
                        key={`${index}-${i}`}
                        className="ledger_child"
                        sx={{
                          backgroundColor: "#fafafa",
                          "&:hover": { backgroundColor: "#f5f5f5" },
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: `2px 2px 2px ${padding + 20}px`,
                          }}
                        >
                          {ledger.name}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "2px 2px 2px 4px " }}
                          className="numeric"
                        >
                          {toIndianCommaFormat(ledger.opening_balance) || ""}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "2px 2px 2px 4px " }}
                          className="numeric"
                        >
                          {toIndianCommaFormat(ledger.debit) || ""}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "2px 2px 2px 4px " }}
                          className="numeric"
                        >
                          {toIndianCommaFormat(ledger.credit) || ""}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "2px 2px 2px 4px " }}
                          className="numeric"
                        >
                          {toIndianCommaFormat(ledger.closing_balance) || ""}
                        </TableCell>
                        <TableCell sx={{ padding: "3px 2px 3px 4px" }}>
                          <DropdownOrInput
                            ledger={ledger}
                            item={item}
                            handleOnChange={handleOnChange}
                          />
                        </TableCell>
                        <TableCell sx={{ padding: "3px 2px 3px 4px" }}>
                        <TextField
                                fullWidth
                                type="text"
                                defaultValue={ledger.child}
                                // value={ledger.child}
                                onBlur={(e) =>
                                  handleOnChange(
                                    ledger.ledgerId,
                                    "child",
                                    e.target.value,
                                    ledger.opening_balance,
                                    ledger.debit,
                                    ledger.credit,
                                    ledger.closing_balance,
                                    ledger.name,
                                    item.name,
                                    ledger.masterHead
                                  )
                                }
                              />
                        </TableCell>
                      </TableRow>
                    )
                )}
            </>
          )}
        </React.Fragment>
      ) : null;
    });
  };

  return (
    <div className="tab_data" style={{height:"500px",overflow:"auto"}}>
      <div className="tableTrail">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ padding: "2px 0px 2px 4px ",width: "50vw",backgroundColor:"#6c757d",color:"white"  }}
                scope="col"
                className="f-13"
              >
                Particulars
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px ",width: "12vw",backgroundColor:"#6c757d",color:"white"  }}
                scope="col"
                className="f-13"
              >
                Open Bal
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px ",width: "10vw",backgroundColor:"#6c757d",color:"white"  }}
                scope="col"
                className="f-13"
              >
                Debit
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px ",width: "10vw",backgroundColor:"#6c757d",color:"white"  }}
                scope="col"
                className="f-13"
              >
                Credit
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px ",width: "12vw",backgroundColor:"#6c757d",color:"white"  }}
                scope="col"
                className="f-13"
              >
                Close Bal
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px " ,width: "28vw",backgroundColor:"#6c757d",color:"white" }}
                scope="col"
                className="f-13"
              >
                Head
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px ",width: "28vw",backgroundColor:"#6c757d",color:"white"  }}
                scope="col"
                className="f-13"
              >
                Child
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="myTable">{renderRows(jsonData, 10)}</TableBody>
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </Table>
      </div>
      {/* <button className="updateButton" onClick={handleSubmit}>
        Update
      </button> */}
    </div>
  );
}

export default AllTrail;
