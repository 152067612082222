import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CompletionProgressBar = ({ completionPercentage }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ width: "20%", marginLeft: "50px" }}
    >
      <Box sx={{ position: "relative", width: "100%" }}>
        <LinearProgress
          variant="determinate"
          value={completionPercentage}
          sx={{
            height: 30,
            borderRadius: 12,
            backgroundColor: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#2DA2FB",
            },
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <Typography
            variant="body2"
            color="black"
          >{`${completionPercentage}% Completion`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default CompletionProgressBar;