import { useEffect, useRef, useState } from "react";

export const useFormData = (
  formData,
  setFormData,
  flattenedData,
  checkAndHideParent
) => {
  const updatedRowIdRef = useRef(null);
  const formDataRef = useRef([]);

  useEffect(() => {
    formData.forEach((f) => {
      checkAndHideParent(f.parentName);
    });
    formDataRef.current = [...formData];
    console.log(formDataRef.current);
  }, [formData, checkAndHideParent]);

  const handleOnChange = (
    id,
    type,
    value,
    opening_balance,
    debit,
    credit,
    closing_balance,
    name,
    parentName,
    masterHead,
    ledger
  ) => {
    const index = formDataRef.current.findIndex((item) => item.id === id);
    const formDataObj = {
      id,
      head:
        type === "head"
          ? value
          : index === -1
          ? ledger?.head
          : formDataRef.current[index]?.head,
      child:
        type === "child"
          ? value
          : index === -1
          ? ledger?.child
          : formDataRef.current[index]?.child,
      opening_balance,
      debit,
      credit,
      closing_balance,
      name,
      parentName,
      masterHead,
    };

    if (index !== -1) {
      const formDataRef = [...formData];
      formDataRef[index] = formDataObj;
      setFormData(formDataRef);
    } else {
      setFormData((prevFormData) => [...prevFormData, formDataObj]);
    }

    updatedRowIdRef.current = id;
  };

  return { formData, handleOnChange, updatedRowIdRef };
};
