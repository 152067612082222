import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { toIndianCommaFormat } from "../../../Utils/math";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

import { fetchWithTempUntagged } from "../../../Utils/API_Axios";
import {
  DefaultLedgerHeadDropDown1,
  DefaultLedgerHeadDropDown2,
  DefaultLedgerHeadDropDown3,
  DefaultLedgerHeadDropDown4,
  DefaultLedgerHeadDropDown5,
  DefaultLedgerHeadDropDown6,
  DefaultLedgerHeadMaster1,
  DefaultLedgerHeadMaster2,
  DefaultLedgerHeadMaster3,
  DefaultLedgerHeadMaster4,
  DefaultLedgerHeadMaster5,
  DefaultLedgerHeadMaster6,
} from "../../../Utils/DefaultLedgerHeadDropDown";
import { useFormData } from "./useFormData";
import { updateTaggedHead } from "../../../Utils/API_Axios";

const masterDropdownMap = [
  { master: DefaultLedgerHeadMaster1, dropdown: DefaultLedgerHeadDropDown1 },
  { master: DefaultLedgerHeadMaster2, dropdown: DefaultLedgerHeadDropDown2 },
  { master: DefaultLedgerHeadMaster3, dropdown: DefaultLedgerHeadDropDown3 },
  { master: DefaultLedgerHeadMaster4, dropdown: DefaultLedgerHeadDropDown4 },
  { master: DefaultLedgerHeadMaster5, dropdown: DefaultLedgerHeadDropDown5 },
  { master: DefaultLedgerHeadMaster6, dropdown: DefaultLedgerHeadDropDown6 },
];

const cache = new Map();

const findDropdownOptions = (parent) => {
  if (cache.has(parent)) {
    return cache.get(parent);
  }

  for (let map of masterDropdownMap) {
    if (map.master.includes(parent)) {
      cache.set(parent, map.dropdown);
      return map.dropdown;
    }
  }

  cache.set(parent, null);
  return null;
};

const DropdownOrInput = React.memo(({ ledger, item, handleOnChange }) => {
  const [head, setHead] = useState(ledger.head ?? "");
  const dropdownOptions = useMemo(
    () => findDropdownOptions(ledger.primaryGroup),
    [ledger.primaryGroup]
  );

  const handleDropdownChange = useCallback(
    (event, newValue) => {
      setHead(newValue);
      handleOnChange(
        ledger.ledgerId,
        "head",
        newValue,
        ledger.opening_balance,
        ledger.debit,
        ledger.credit,
        ledger.closing_balance,
        ledger.name,
        item.name,
        ledger.masterHead,
        ledger
      );
    },
    [handleOnChange, ledger, item]
  );

  const handleInputBlur = useCallback(
    (e) => {
      handleOnChange(
        ledger.ledgerId,
        "child",
        e.target.value,
        ledger.opening_balance,
        ledger.debit,
        ledger.credit,
        ledger.closing_balance,
        ledger.name,
        item.name,
        ledger.masterHead,
        ledger
      );
    },
    [handleOnChange, ledger, item]
  );

  return dropdownOptions ? (
    <Autocomplete
      value={head}
      onChange={handleDropdownChange}
      options={dropdownOptions}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  ) : (
    <TextField
      fullWidth
      value={ledger.child}
      type="text"
      onBlur={handleInputBlur}
    />
  );
});

const flattenData = (data, parent = null) => {
  let result = [];
  data.forEach((item) => {
    if (item) {
      const newItem = {
        ...item,
        parentName: parent ? parent.name : null,
      };
      result.push(newItem);
      if (item.children && item.children.length > 0) {
        const children = flattenData(item.children, item);
        result = result.concat(children);
      }
    }
  });
  return result;
};

const Tagged = forwardRef((props, ref) => {
  const { toggle, loading, jsonData, setJsonData, companyID, fetchData } =
    props;
  const [filledChildren, setFilledChildren] = useState({});
  const [expandedRows, setExpandedRows] = useState({});
  const [loadingIndex, setLoadingIndex] = useState(null);
  const handleRowClick = useCallback((rowName) => {
    setLoadingIndex(rowName);
    setTimeout(() => {
      setExpandedRows((prev) => ({ ...prev, [rowName]: !prev[rowName] }));
      setLoadingIndex(null);
    }, 0);
  }, []);

  // State to track expanded rows
  const flattenedData = useMemo(() => flattenData(jsonData), [jsonData]);

  useEffect(() => {
    if (toggle) {
      const expandedState = {};
      flattenedData.forEach((item) => {
        if (item.name && item.isAnyTagged) {
          expandedState[item.name] = true;
        }
      });
      setExpandedRows(expandedState);
    } else {
      setExpandedRows({});
    }
  }, [toggle]);

  const renderRows = useCallback(
    (data, padding, level = 0) => {
      if (!data) return null;
      return data.map((item, index) => {
        const isParentRow = item?.name && item?.isAnyTagged;
        return isParentRow ? (
          <React.Fragment key={index}>
            <TableRow
              sx={{
                cursor: "pointer",
                backgroundColor: "#f0f0f0",
                "&:hover": { backgroundColor: "#e0e0e0" },
              }}
            >
              <TableCell
                onClick={() => handleRowClick(item.name)}
                sx={
                  level === 0
                    ? {
                        backgroundColor: "#2DA2FB",
                        color: "#fff",
                        padding: `2px 0px 2px 2px`,
                        cursor: "pointer",
                      }
                    : {
                        backgroundColor: "#2DA2FB",
                        color: "#fff",
                        padding: `0px 0px 0px ${padding + 10}px`,
                        cursor: "pointer",
                      }
                }
              >
                {level === 0 && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    sx={{ color: "white", padding: "0px", margin: "0px" }}
                  >
                    {expandedRows[item.name] ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                )}
                {item.name}
                {loadingIndex === item.name && (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loadingIndex === item.name}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#2DA2FB",
                  color: "#fff",
                  padding: "2px 2px 2px 10px ",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#2DA2FB",
                  color: "#fff",
                  padding: "2px 2px 2px 10px ",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#2DA2FB",
                  color: "#fff",
                  padding: "2px 2px 2px 10px ",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#2DA2FB",
                  color: "#fff",
                  padding: "2px 2px 2px 10px ",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#2DA2FB",
                  color: "#fff",
                  padding: "2px 2px 2px 10px ",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#2DA2FB",
                  color: "#fff",
                  padding: "2px 2px 2px 10px ",
                }}
              ></TableCell>
            </TableRow>
            {level === 0 ? (
              <>
                {expandedRows[item.name] && (
                  <>
                    {item.children &&
                      renderRows(item.children, padding + 10, level + 1)}
                    {item.ledger &&
                      item.ledger.map(
                        (ledger, i) =>
                          ((ledger.head && ledger.child) ||
                            (ledger.isTagged &&
                              !ledger.head &&
                              !ledger.child)) && (
                            <TableRow
                              key={`${index}-${i}`}
                              className="ledger_child"
                              sx={{
                                backgroundColor: "#fafafa",
                                "&:hover": { backgroundColor: "#f5f5f5" },
                              }}
                            >
                              <TableCell
                                sx={{
                                  padding: `2px 2px 2px ${padding + 20}px`,
                                }}
                              >
                                {ledger.name}
                              </TableCell>
                              <TableCell
                                sx={{ padding: "2px 2px 2px 4px " }}
                                className="numeric"
                              >
                                {toIndianCommaFormat(ledger.opening_balance) ||
                                  ""}
                              </TableCell>
                              <TableCell
                                sx={{ padding: "2px 2px 2px 4px " }}
                                className="numeric"
                              >
                                {toIndianCommaFormat(ledger.debit) || ""}
                              </TableCell>
                              <TableCell
                                sx={{ padding: "2px 2px 2px 4px " }}
                                className="numeric"
                              >
                                {toIndianCommaFormat(ledger.credit) || ""}
                              </TableCell>
                              <TableCell
                                sx={{ padding: "2px 2px 2px 4px " }}
                                className="numeric"
                              >
                                {toIndianCommaFormat(ledger.closing_balance) ||
                                  ""}
                              </TableCell>
                              <TableCell sx={{ padding: "3px 2px 3px 4px" }}>
                                <DropdownOrInput
                                  ledger={ledger}
                                  item={item}
                                  handleOnChange={handleOnChange}
                                />
                              </TableCell>
                              <TableCell sx={{ padding: "3px 2px 3px 4px" }}>
                                <TextField
                                  fullWidth
                                  type="text"
                                  defaultValue={ledger.child}
                                  // value={ledger.child}
                                  onBlur={(e) =>
                                    handleOnChange(
                                      ledger.ledgerId,
                                      "child",
                                      e.target.value,
                                      ledger.opening_balance,
                                      ledger.debit,
                                      ledger.credit,
                                      ledger.closing_balance,
                                      ledger.name,
                                      item.name,
                                      ledger.masterHead,
                                      ledger
                                    )
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          )
                      )}
                  </>
                )}
              </>
            ) : (
              <>
                {item.children &&
                  renderRows(item.children, padding + 10, level + 1)}
                {item.ledger &&
                  item.ledger.map(
                    (ledger, i) =>
                      ((ledger.head && ledger.child) ||
                        (ledger.isTagged && !ledger.head && !ledger.child)) && (
                        <TableRow
                          key={`${index}-${i}`}
                          className="ledger_child"
                          sx={{
                            backgroundColor: "#fafafa",
                            "&:hover": { backgroundColor: "#f5f5f5" },
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: `2px 2px 2px ${padding + 20}px`,
                            }}
                          >
                            {ledger.name}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "2px 2px 2px 4px " }}
                            className="numeric"
                          >
                            {toIndianCommaFormat(ledger.opening_balance) || ""}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "2px 2px 2px 4px " }}
                            className="numeric"
                          >
                            {toIndianCommaFormat(ledger.debit) || ""}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "2px 2px 2px 4px " }}
                            className="numeric"
                          >
                            {toIndianCommaFormat(ledger.credit) || ""}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "2px 2px 2px 4px " }}
                            className="numeric"
                          >
                            {toIndianCommaFormat(ledger.closing_balance) || ""}
                          </TableCell>
                          <TableCell sx={{ padding: "3px 2px 3px 4px" }}>
                            <DropdownOrInput
                              ledger={ledger}
                              item={item}
                              handleOnChange={handleOnChange}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: "3px 2px 3px 4px" }}>
                            <TextField
                              fullWidth
                              type="text"
                              defaultValue={ledger.child}
                              // value={ledger.child}
                              onBlur={(e) =>
                                handleOnChange(
                                  ledger.ledgerId,
                                  "child",
                                  e.target.value,
                                  ledger.opening_balance,
                                  ledger.debit,
                                  ledger.credit,
                                  ledger.closing_balance,
                                  ledger.name,
                                  item.name,
                                  ledger.masterHead,
                                  ledger
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      )
                  )}
              </>
            )}
          </React.Fragment>
        ) : null;
      });
    },
    [expandedRows, loadingIndex, handleRowClick]
  );

  const checkAndHideParent = useCallback(
    (parentName) => {
      if (!parentName) return;
      const childrenOfParent = flattenedData.find(
        (item) => item.name === parentName
      );

      const allFilled = childrenOfParent?.ledger?.every((child) => {
        return (
          (child.head && child.child) ||
          (child.isTagged && !child.head && !child.child)
        );
      });

      if (allFilled) {
        setFilledChildren((prev) => ({ ...prev, [parentName]: true }));
      }
    },
    [flattenedData]
  );

  const { handleOnChange, formData, updatedRowIdRef } = useFormData(
    jsonData,
    setJsonData,
    flattenedData,
    checkAndHideParent
  );

  const updateHiddenRows = useCallback((data, actualFilledChildren) => {
    let filledChildren = { ...actualFilledChildren };
    const checkAndHide = (node) => {
      if (!node || !node.children) return;
      if (filledChildren?.[node?.name]) return;

      node.children.forEach((child) => {
        if (child) {
          checkAndHide(child);
        }
      });
      const areAllChildrenFilled = node.children.every(
        (child) => child === null || (child.name && filledChildren[child.name])
      );
      if (areAllChildrenFilled && node.name) {
        const areLedgersFilled = node.ledger.every(
          (ledger) =>
            ledger === null ||
            (ledger.head && ledger.child) ||
            (ledger.isTagged && !ledger.head && !ledger.child)
        );

        filledChildren[node.name] = areLedgersFilled;
      }
    };

    data.forEach((node) => {
      if (node) {
        checkAndHide(node);
      }
    });
    setFilledChildren((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(filledChildren)) {
        return filledChildren;
      }
      return prev;
    });
  }, []);
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      handleSubmit();
    },
  }));
  const handleSubmit = async () => {
    try {
      const userID = JSON.parse(localStorage.getItem("userDetails"))._id;
      const updatedRowId = updatedRowIdRef.current;

      if (updatedRowId !== null) {
        const updatedRow = formData.find((item) => item.id === updatedRowId);

        if (updatedRow) {
          const jsonData = [
            {
              ...updatedRow,
            },
          ];

          const response = await updateTaggedHead({
            jsonData,
            companyID,
            userID,
          });
          if (response?.data?.errorStatus === 200) {
            toast.success(`Data Updated Successfully....`);
          } else if (response?.data?.errorStatus === 400) {
            toast.warning(`Message: ${response?.data?.message}`);
          } else if (response?.data?.errorStatus === 401) {
            toast.error(`Error: ${response?.data?.error}`);
          } else {
            toast.error(`There is some issue try after sometime later.`);
          }
        } else {
          console.log("NO UPDATED ROW");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      fetchData(companyID);
    }
  };

  useEffect(() => {
    if (jsonData) {
      updateHiddenRows(jsonData, filledChildren);
    }
  }, [jsonData, filledChildren, updateHiddenRows]);

  return (
    <div className="tab_data" style={{ height: "500px", overflow: "auto" }}>
      <div className="tableTrail">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  padding: "2px 0px 2px 4px",
                  width: "50vw",
                  backgroundColor: "#6c757d",
                  color: "white",
                }}
                scope="col"
                className="f-13"
              >
                Particulars
              </TableCell>
              <TableCell
                sx={{
                  padding: "2px 0px 2px 2px",
                  width: "12vw",
                  backgroundColor: "#6c757d",
                  color: "white",
                }}
                scope="col"
                className="f-13"
              >
                Open Bal
              </TableCell>
              <TableCell
                sx={{
                  padding: "2px 0px 2px 2px",
                  width: "10vw",
                  backgroundColor: "#6c757d",
                  color: "white",
                }}
                scope="col"
                className="f-13"
              >
                Debit
              </TableCell>
              <TableCell
                sx={{
                  padding: "2px 0px 2px 2px",
                  width: "10vw",
                  backgroundColor: "#6c757d",
                  color: "white",
                }}
                scope="col"
                className="f-13"
              >
                Credit
              </TableCell>
              <TableCell
                sx={{
                  padding: "2px 0px 2px 2px",
                  width: "12vw",
                  backgroundColor: "#6c757d",
                  color: "white",
                }}
                scope="col"
                className="f-13"
              >
                Close Bal
              </TableCell>
              <TableCell
                sx={{
                  padding: "2px 0px 2px 2px",
                  width: "28vw",
                  backgroundColor: "#6c757d",
                  color: "white",
                }}
                scope="col"
                className="f-13"
              >
                Head
              </TableCell>
              <TableCell
                sx={{
                  padding: "2px 0px 2px 2px",
                  width: "28vw",
                  backgroundColor: "#6c757d",
                  color: "white",
                }}
                scope="col"
                className="f-13"
              >
                Child
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="myTable">{renderRows(jsonData, 10)}</TableBody>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Table>
      </div>
    </div>
  );
});

export default Tagged;
