import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Autocomplete,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  addSingartureUseFulLifeAPI,
  getAutoFillUseFulLifeAPI,
  getFixedAssetsChildsAPI,
  getLedgerNameForFixedAssetsAPI,
} from "../../../../Utils/API_Axios";

function FixedAsset({ clientForm }) {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedLedgers, setSelectedLedgers] = useState([]);
  const [faChild, setFAChild] = useState([]);
  const [usefulLife, setUsefulLife] = useState({});
  const [oldValues, setOldValues] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitBtnTxt, setSubmitBtnTxt] = useState('Submit');

  const handleSearch = useCallback(
    debounce(async (value) => {
      if (value.trim() === "") {
        setSearchResults([]);
        return;
      }

      setSearchResults([]);
      // Call the API
      let payload = {
        ledger: value,
        Company_ID: clientForm.company_id,
        User_ID: clientForm.user_id,
      };
      const ledgerLists = await getLedgerNameForFixedAssetsAPI(payload);
      setSearchResults(ledgerLists?.data?.ledgers || []);

      


    }, 300), // Adjust debounce time as needed
    [clientForm.company_id, clientForm.user_id]
  );

  useEffect(() => {
    getOldValues();
    fetchFixedAssetsChildName();
  }, []);

  const fetchFixedAssetsChildName = async () => {
    let payload = {
      fy_year: clientForm.fy,
      Company_ID: clientForm.company_id,
      User_ID: clientForm.user_id,
    };
    const res = await getFixedAssetsChildsAPI(payload);
    setFAChild(res?.data?.fixedAssets);
  };
  const getOldValues = async () => {
    let payload = {
      fy_year: clientForm.fy,
      Company_ID: clientForm.company_id,
      User_ID: clientForm.user_id,
    };
    const res = await getAutoFillUseFulLifeAPI(payload);
    setOldValues(res?.data?.fixedAssets);
    let oo = res?.data?.fixedAssets;

    if (oo?.dep_list && oo.dep_list?.length > 0) {
      // Iterate over each ledger in dep_list
      oo.dep_list.forEach((ledger) => {
        // Check if the ledger is not already in selectedLedgers
        if (!selectedLedgers.includes(ledger)) {
          // Add the ledger to selectedLedgers if not found
          setSelectedLedgers((prevSelectedLedgers) => [
            ...prevSelectedLedgers,
            {name:ledger},
          ]);
        }
      });
    }

    if(oo?.useful_life){
      // oo.useful_life.forEach((item) => {
      //   setUsefulLife
      // })
      setUsefulLife(oo?.useful_life)
    }

  };
  const handleSelectLedger = (event, ledger) => {
    if (ledger && !selectedLedgers.some((item) => item.name === ledger.name)) {
      setSelectedLedgers([...selectedLedgers, ledger]);
    }
    setSearchResults([]);
  };

  const handleRemoveLedger = (ledgerId) => {
    setSelectedLedgers(
      selectedLedgers.filter((ledger) => ledger._id !== ledgerId)
    );
  };

  const handleUsefulLifeChange = (item, value) => {
    setUsefulLife((prev) => ({
      ...prev,
      [item]: parseInt(value),
    }));
  };

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const handleFormSubmit = async () => {
    setIsSubmit(true);
    setSubmitBtnTxt('Submiting');
    const data = {
      fy_year: clientForm.fy,
      Company_ID: clientForm.company_id,
      User_ID: clientForm.user_id,
      selectedLedgers,
      usefulLife,
    };
    const res = await addSingartureUseFulLifeAPI(data);
   if(res?.data?.errorStatus == 200){
    setIsSubmit(false);
    setSubmitBtnTxt('Submited');
   }else{
    setIsSubmit(false);
    setSubmitBtnTxt('Realod');
   }
  };

  return (
    <div className="mt-4 p-0 d-flex flex-column gap-10">
      <div className="warning">
        <p>
          <span style={{ color: "red" }}>Please Note:</span> We don't calculate
          the Depreciation Currently. So, you need to make sure all the
          Depreciation Entries are passed in Tally and we take data accordingly.
        </p>
      </div>
      <div className="fixed_asset">
        <div className="tab_items">
          <span className="span_other">Signatory Details</span>
        </div>
        <div className="search_box">
          <p>
            Search & Select the "Depreciation" Ledger showing in Profit & Loss
            A/c. (Ledger which Contains Depreciation Entries)
          </p>
          <Autocomplete
            freeSolo
            options={searchResults}
            getOptionLabel={(option) => option.name}
            onInputChange={(e, value) => handleSearch(value)}
            onChange={handleSelectLedger}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search your Ledger"
                type="text"
                size="small"
                variant="outlined"
                className="searchField"
              />
            )}
          />
        </div>
        <div className="other_details_list">
          <ol>
            {selectedLedgers.map((ledger) => (
              <li key={ledger._id}>
                <b>{ledger.name}</b>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemoveLedger(ledger._id)}
                >
                  <DeleteIcon />
                </IconButton>
              </li>
            ))}
          </ol>
        </div>
        <div className="lineBreak" />
        <div className="tab_items">
          <span className="span_other">Useful Life</span>
        </div>
        <div className="other_details_table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "8px",
                    backgroundColor: "#2DA2FB",
                    border: "1px solid black",
                  }}
                >
                  Asset Name
                </TableCell>
                <TableCell
                  sx={{
                    padding: "8px",
                    backgroundColor: "#2DA2FB",
                    border: "1px solid black",
                  }}
                >
                  Useful Life (in Years)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faChild?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ padding: "8px", border: "1px solid black" }}>
                    {index + 1}. {item}
                  </TableCell>
                  <TableCell sx={{ padding: "8px", border: "1px solid black" }}>
                    <TextField
                      name={`faChild[${item}]`}
                      type="number"
                      value={usefulLife[item]}
                      onChange={(e) =>
                        handleUsefulLifeChange(item, e.target.value)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

      <Button
        type="submit"
        className={`btn btn-success mt-5`}
        sx={{ bgcolor: "#383b3f", width: "100px" }}
        variant="contained"
        disableElevation
        onClick={handleFormSubmit}
        disabled={isSubmit}
      >
        {submitBtnTxt}
      </Button>
      
    </div>
  );
}

export default FixedAsset;
