import React, { useState, useRef, useEffect, useCallback } from "react";
import Tagged from "./trial/Tagged";
import Untagged from "./trial/Untagged";
import AllTrail from "./trial/AllTrail";
import { Tabs, Tab, Box, Button } from "@mui/material";
import CompletionProgressBar from "../../component/CompletionPercentage";
import { useDispatch } from "react-redux";
import { completion } from "../../store/slices/counterSlice";
import { fetchWithTempUntagged } from "../../Utils/API_Axios";
import { toast } from "react-toastify";

const Trials = ({ companyID, fy }) => {
  const untaggedRef = useRef();
  const taggedRef = useRef();
  const [currentTab, setCurrentTab] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [totalLedger, setTotalLedgers] = useState(0);
  const [totalTagged, setTotalTagged] = useState(0);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [jsonData, setJsonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const countLedgers = (data) => {
    let totalLedgers = 0;

    const traverse = (items) => {
      items?.forEach((item) => {
        if (item?.name && item?.isAnyTagged) {
          if (item.ledger) {
            item.ledger.forEach((ledger) => {
              if (ledger?.isTagged) totalLedgers++;
            });
          }
        }

        if (item.children) {
          traverse(item.children);
        }
      });
    };

    traverse(data);
    return { totalLedgers };
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchWithTempUntagged({ companyID });
      if (response?.data?.errorStatus === 200) {
        setJsonData(response?.data?.data);
        const { totalLedgers } = countLedgers(response?.data?.data);
        setTotalTagged(totalLedgers);
      } else if (response?.data?.errorStatus === 401) {
        toast.error(`Error: ${response?.data?.error}`);
      } else {
        toast.error(`There is some issue on fetching Trail unTagged not found.`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [companyID]);

  useEffect(() => {
    const total = totalLedger + totalTagged;
    const percentage = total ? Math.round((totalTagged / total) * 100) : 0;
    setCompletionPercentage(percentage);
    dispatch(completion(percentage));
  }, [totalTagged, totalLedger, dispatch]);

  useEffect(() => {
    fetchData();
  }, [companyID, currentTab, fetchData]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleSwitchChange = (event) => {
    setToggle(event.target.checked);
  };

  const handleSubmit = () => {
    if (currentTab === 0) {
      untaggedRef.current.handleSubmit();
    } else if (currentTab === 1) {
      taggedRef.current.handleSubmit();
    }
  };

  return (
    <div className="white_body">
      <div className="tab_container" style={{ position: "relative" }}>
        <Box sx={{ display: "flex", position: "sticky", top: "85px", zIndex: 1000, backgroundColor: "white", overflow: "hidden" }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="trials tabs"
            sx={{
              ".Mui-selected": { color: `#2DA2FB` },
              ".MuiTabs-indicator": { bgcolor: "#2DA2FB" },
              borderBottom: 1,
              borderColor: "divider",
              width: "280px",
            }}
          >
            <Tab label="Un-Tagged" sx={{ textTransform: "capitalize" }} />
            <Tab label="Tagged" sx={{ textTransform: "capitalize" }} />
            <Tab label="All" sx={{ textTransform: "capitalize" }} />
          </Tabs>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "70%", marginLeft: "20px" }}>
            <Button
              variant="contained"
              sx={{ bgcolor: "#383b3f", width: "100px", height: "38px", marginTop: "9px", marginLeft: "40px" }}
              color="primary"
              onClick={handleSubmit}
              disableElevation
            >
              Update
            </Button>

            {/* <SwitchWithLabel checked={toggle} onChange={handleSwitchChange} /> */}
            {currentTab === 0 && <CompletionProgressBar completionPercentage={completionPercentage} />}
          </Box>
        </Box>

        {currentTab === 0 && (
          <Box sx={{ margin: "20px 0" }}>
            <Untagged toggle={toggle} companyID={companyID} ref={untaggedRef} settotalUnLedgers={setTotalLedgers} />
          </Box>
        )}
        {currentTab === 1 && (
          <Box sx={{ margin: "20px 0" }}>
            <Tagged  ref={taggedRef} toggle={toggle} companyID={companyID} loading={loading} setLoading={setLoading} jsonData={jsonData} setJsonData={setJsonData} fetchData={fetchData} />
          </Box>
        )}  
        {currentTab === 2 && (
          <Box sx={{ margin: "20px 0" }}>
            <AllTrail toggle={toggle} companyID={companyID} />
          </Box>
        )}
      </div>
    </div>
  );
};

export default Trials;
