import React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const ClientDetails = ({ clientForm, handleClientForm, REQUIRED_ERROR }) => {
  const onSubmit = (data) => {
    handleClientForm(data);
  };

  return (
    <div className="white_body">
      <div className="company_details_tab">
        <div className="tab_items">
          <span className="f-13 custom-button">Company Details</span>
        </div>
        <div className="row my-5">
          <div className="col-md-6">
            <TextField
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                  "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="client_name"
              value={clientForm.client_name}
              error={REQUIRED_ERROR.client_name}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Company Name"
              fullWidth
            />
          </div>
          <div className="col-md-6">
            <div className="flex_input">
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: "14px",
                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                  },
                }}
                inputProps={{ style: { fontSize: "14px" } }}
                name="cin"
                value={clientForm.cin}
                onChange={(e) => handleClientForm(e)}
                size="small"
                label="CIN Number"
                fullWidth
              />
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel
                  id="rupee-in"
                  sx={{
                    fontSize: "14px",
                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                  }}
                >
                  Rupees in
                </InputLabel>
                <Select
                  sx={{
                    fontSize: "14px",
                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                  }}
                  name="rupees_in"
                  labelId="rupee-in"
                  label="Rupees in"
                  error={REQUIRED_ERROR.rupees_in}
                  value={clientForm.rupees_in}
                  onChange={(e) => handleClientForm(e)}
                >
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                    }}
                    value="Rs In ‘hundreds"
                  >
                    Rs in ‘Hundreds
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                    }}
                    value="Rs In ‘thousands"
                  >
                    Rs in ‘Thousands
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                    }}
                    value="Rs In ‘lakhs"
                  >
                    Rs in ‘Lakhs
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                    }}
                    value="Rs In ‘crores"
                  >
                    Rs in ‘Crores
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            
          </div>
          <div className="row my-5 mb-1">
          <div className="col-md-6">
            <TextField
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                  "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="company_engaged_into"
              value={clientForm.company_engaged_into}
              error={REQUIRED_ERROR.company_engaged_into}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Company engaged into"
              fullWidth
            />
          </div>
          <div className="col-md-6">
            <div className="flex_input">
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel
                  id="rupee-in"
                  sx={{
                    fontSize: "14px",
                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                  }}
                >
                  Company type
                </InputLabel>
                <Select
                  sx={{
                    fontSize: "14px",
                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                  }}
                  name="company_type"
                  labelId="company_type"
                  label="Company type"
                  error={REQUIRED_ERROR.company_type}
                  value={clientForm.company_type}
                  onChange={(e) => handleClientForm(e)}
                >
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                    }}
                    value="Private Limited"
                  >
                    Private Limited
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                    }}
                    value="Public Limited"
                  >
                    Public Limited
                  </MenuItem>
                </Select>
              </FormControl>
              <div className="col-md-3">
                <TextField
                  sx={{
                    fontSize: "14px",
                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                  }}
                  inputProps={{ style: { fontSize: "14px" } }}
                  name="date_of_incorporation"
                  error={REQUIRED_ERROR.date_of_incorporation}
                  value={clientForm.date_of_incorporation}
                  onChange={(e) => handleClientForm(e)}
                  size="small"
                  label="Date of Incorporation"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
          <div className="col-md-6 my-5">
            <TextField
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                  "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="client_address"
              value={clientForm.client_address}
              error={REQUIRED_ERROR.client_address}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Address"
              multiline
              rows={4}
              fullWidth
            />
          </div>
        </div>

       
      </div>

      <div className="signatory_details_tab">
        <div className="tab_items">
          <span className="f-13 custom-button">Signatory Details</span>
        </div>
        <div className="row my-5">
          <div className="col-md-6">
            <div className="flex_input">
              <TextField
                inputProps={{ style: { fontSize: "14px" } }}
                InputLabelProps={{
                  sx: {
                    fontSize: "14px",
                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                  },
                }}
                name="signatory_1"
                error={REQUIRED_ERROR.signatory_1}
                value={clientForm.signatory_1}
                onChange={(e) => handleClientForm(e)}
                size="small"
                label="Signatory Name-1"
                fullWidth
              />
              <TextField
                inputProps={{ style: { fontSize: "14px" } }}
                name="designatory_1"
                error={REQUIRED_ERROR.designatory_1}
                value={clientForm.designatory_1}
                onChange={(e) => handleClientForm(e)}
                size="small"
                label="Designation-1"
                fullWidth
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="flex_input">
              <TextField
                inputProps={{ style: { fontSize: "14px" } }}
                sx={{
                  fontSize: "14px",
                  "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                }}
                name="signatory_2"
                error={REQUIRED_ERROR.signatory_2}
                value={clientForm.signatory_2}
                onChange={(e) => handleClientForm(e)}
                size="small"
                label="Signatory Name-2"
                fullWidth
              />
              <TextField
                inputProps={{ style: { fontSize: "14px" } }}
                name="designatory_2"
                error={REQUIRED_ERROR.designatory_2}
                value={clientForm.designatory_2}
                onChange={(e) => handleClientForm(e)}
                size="small"
                label="Designation-2"
                fullWidth
              />
            </div>
          </div>
          {/* <div className="col-md-6 my-5">
            <div className="flex_input">
              <TextField
                inputProps={{ style: { fontSize: "14px" } }}
                sx={{
                  fontSize: "14px",
                  "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                }}
                name="S_sn3"
                value={clientForm.S_sn3}
                onChange={(e) => handleClientForm(e)}
                size="small"
                label="Signatory Name-3"
                fullWidth
              />
              <TextField
                inputProps={{ style: { fontSize: "14px" } }}
                name="S_dn3"
                value={clientForm.S_dn3}
                onChange={(e) => handleClientForm(e)}
                size="small"
                label="Designation-3"
                fullWidth
              />
            </div>
          </div> */}
        </div>
      </div>

      <div className="auditor_details_tab">
        <div className="tab_items">
          <span className="f-13 custom-button">Auditor Details</span>
        </div>
        <div className="row my-5">
          <div className="col-md-4">
            <TextField
              sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="firmname"
              value={clientForm.firmname}
              error={REQUIRED_ERROR.firmname}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Firm Name"
              fullWidth
            />
          </div>
          <div className="col-md-4">
            <TextField
              sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="auditor_name"
              error={REQUIRED_ERROR.auditor_name}
              value={clientForm.auditor_name}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Auditor Name"
              fullWidth
            />
          </div>
          <div className="col-md-4">
            <TextField
              sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="a_designation"
              error={REQUIRED_ERROR.a_designation}
              value={clientForm.a_designation}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Designation"
              fullWidth
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-3">
            <TextField
              sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="a_frn"
              value={clientForm.a_frn}
              error={REQUIRED_ERROR.firmname}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Firm Regn No"
              fullWidth
            />
          </div>
          <div className="col-md-3">
            <TextField
              sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="a_membership_no"
              error={REQUIRED_ERROR.a_membership_no}
              value={clientForm.a_membership_no}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Membership No"
              type="number"
              fullWidth
            />
          </div>
          <div className="col-md-3">
            <TextField
              sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="a_date_of_signing"
              error={REQUIRED_ERROR.a_date_of_signing}
              value={clientForm.a_date_of_signing}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Date of Signing"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-3">
            <TextField
              sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="a_udin"
              error={REQUIRED_ERROR.a_udin}
              value={clientForm.a_udin?.toUpperCase()}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="UDIN"
              fullWidth
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-3">
            <TextField
              sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
              }}
              inputProps={{ style: { fontSize: "14px" } }}
              name="a_place_of_signing"
              error={REQUIRED_ERROR.a_place_of_signing}
              value={clientForm.a_place_of_signing}
              onChange={(e) => handleClientForm(e)}
              size="small"
              label="Place of Signing"
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
