import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "../../App";
import { toast } from "react-toastify";
import {
  ERROR_FIELD_TYPES,
  checkEmptyFieldOrError,
} from "../../Utils/Constants";
import { registerUser } from "../../Utils/API_Axios";
import { useCustomQuery } from "../../hooks/useQueryHook";
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firmname: "",
    fname: "",
    email: "",
    mobile: "",
    password: "",
    cpassword: "",
  });

  const [hidePass, setHidePass] = useState(true);
  const [hidePass1, setHidePass1] = useState(true);
  const onChangeHandle = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async () => {
    // check matching password
    if (formData.password !== formData.cpassword) {
      return toast.error("Password and Confirm Password is Not Match.");
    }

    // check valid email address
    if (checkEmptyFieldOrError(formData.email, ERROR_FIELD_TYPES.EMAIL)) {
      return toast.error(`Email Field is Required, and a Valid Email Address`);
    }
    // check valid phone number
    if (checkEmptyFieldOrError(formData.mobile, ERROR_FIELD_TYPES.PHONE)) {
      return toast.error(
        `Phone Field is Required, and a Valid Phone/Mobile Number`
      );
    }

    // check valid password

    // if (checkEmptyFieldOrError(formData.password, ERROR_FIELD_TYPES.PASSWORD)) {
    //   return toast.error(
    //     `Please enter a valid password. at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special symbol such as @$!%*?&.`
    //   );
    // }
    const { response: _registerUser } = useCustomQuery(formData, registerUser(formData))

    if (_registerUser?.data?.errorStatus === 200) {
      toast
        .success(`Registration Successfull, Please verfiy your token, and then You can now login!!`, {
          onClose: () => {
            navigate("/auth/verfiy-token");
          }
        })

      console.log(_registerUser);

      // empty the form fields
      setFormData({
        firmname: "",
        fname: "",
        email: "",
        mobile: "",
        password: "",
        cpassword: "",
      });

      // now redirect to login page...
    } else if (_registerUser?.data?.errorStatus === 401) {
      return toast.error(`Error: ${_registerUser?.data?.error}`);
    } else {
      return toast.error(
        `There is some issue on registration try after sometime later.`
      );
    }
  };

  return (
    <div style={{ maxHeight: "100vh", height: "100%",overflow:"hidden"}}>
      <div style={{fontSize:"16px"}} className="row">
        <div className="col-md-4 login_img_box">
          <img
            src={`${PUBLIC_URL}/images/login_img.png`}
            alt=""
            className="login_img"
          />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5 signup_form_box">
          <img
            src={`${PUBLIC_URL}/images/logo.png`}
            alt=""
            style={{ height: "8vh" }}
          />
          <div style={{ width: "70%" }}>
              <div className="mb-3">
                <FormControl  fullWidth>
                  <TextField
                    label="Firm Name"
                    variant="outlined"
                    type="text"
                    onChange={onChangeHandle}
                    value={formData.firmname}
                    name="firmname"
                    sx={{ mt: 1 }}
                  />
                </FormControl>
              </div>
              <div className="mb-3">
                <FormControl fullWidth>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    type="text"
                    onChange={onChangeHandle}
                    value={formData.fname}
                    name="fname"
                    sx={{ mt: 1 }}
                  />
                </FormControl>
              </div>
              <div className="mb-3">
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    variant="outlined"
                    type="email"
                    aria-describedby="emailHelp"
                    onChange={onChangeHandle}
                    value={formData.email}
                    name="email"
                    sx={{ mt: 1 }}
                  />
                </FormControl>
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div className="mb-3">
                <FormControl fullWidth>
                  <TextField
                    label="Mobile"
                    variant="outlined"
                    type="number"
                    onChange={onChangeHandle}
                    value={formData.mobile}
                    name="mobile"
                    sx={{ mt: 1 }}
                  />
                </FormControl>
              </div>
              <div className="mb-3">
                <FormControl fullWidth sx={{ marginTop: 1 }}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    label="Password"
                    variant="outlined"
                    type={hidePass1 ? 'password' : 'text'}
                    onChange={onChangeHandle}
                    value={formData.password}
                    name="password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setHidePass1(!hidePass1) }}
                          edge="end"
                        >
                          {hidePass1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="mb-4">
                <FormControl fullWidth sx={{ marginTop: 1 }}>
                  <InputLabel htmlFor="password">Confirm Password</InputLabel>
                  <OutlinedInput
                    label="Confirm Password"
                    variant="outlined"
                    type={hidePass ? 'password' : 'text'}
                    id="exampleInputPassword1"
                    onChange={onChangeHandle}
                    value={formData.cpassword}
                    name="cpassword"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setHidePass(!hidePass) }}
                          edge="end"
                        >
                          {hidePass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <p style={{ fontSize: "12px" }}>
                  Confirm password must match the password.
                </p>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleFormSubmit}
                className="btn btn-dark btn-block w-100  line-height-2"
                disableElevation
                disableFocusRipple
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#393939"
                  }
                }}
              >
                Sign Up
              </Button>

            <p className="my-3 text-center rm_link">
              Already have an account??  &nbsp;
              <NavLink to="/" className='rm_link'>
                Login
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
