import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import {
  fetchFYYears,
  uploadPreviousYearTB,
  uploadPreviousYearTBUpdated,
} from "../../../Utils/API_Axios";
import { MasterHeadForPreviousDB } from "../../../Utils/MasterHead";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  CircularProgress,
  Autocomplete,
  TextField
} from "@mui/material";

const PreviousYearTB = ({ companyID, setUploadDataStatus  }) => {
  const [fyYears, setFyYears] = useState([]);
  const [formData, setFormData] = useState({});
  const [xlsxData, setXLSXData] = useState([]);
  const [xfile, setFile] = useState("");
  const [headErros, setHeadErrors] = useState([]);
  const [updateLedgerHead, setUpdateLedgerHead] = useState({});
  const [duplicateD, setDuplicateD] = useState([]);
  const [xlsxError, setXlsxError] = useState([]);
  const [btnDisble, setBtnDisable] = useState(true);
  const [btnDisbleAll, setBtnDisableAll] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loaderAll,setLoaderAll] = useState(false)
  const uniqueHeaders = [];

  const uniqueHeadErrors = headErros?.filter((_error) => {
    if (!uniqueHeaders.includes(_error.Head)) {
      uniqueHeaders.push(_error.Head);
      return true;
    }
    return false;
  });
  useEffect(() => {
    fetchFY();
  }, []);

  useEffect(()=>{
    setUploadDataStatus(true)
  },[])
  const fileOnChange = (e) => {
    // setXlsxError([])
    setHeadErrors([]);
    if (e.target.files[0]) {
      const fileExtension = e.target.files[0].name.split('.').pop().toLowerCase();
      if (fileExtension === "xls" || fileExtension === "xlsx") {
        setFile(e.target.files[0]);
        setBtnDisable(false);
      } else {
        setBtnDisableAll(true);
        setXlsxError([]);
        setBtnDisable(true);
        setHeadErrors([]);
        setXLSXData([]);
        e.target.value = "";
        setFile("");
        alert("Only Excel File")
      }
    }else{
        setBtnDisableAll(true);
        setXlsxError([]);
        setBtnDisable(true);
        setHeadErrors([]);
        setXLSXData([]);
        setFile("");
    }
  };

  const validateDataTypes = (data) => {
    const expectedTypes = [
      "string",
      "number",
      "number",
      "number",
      "number",
      "string",
      "string",
    ]; // Example: Expected data types for each column
    const errors = [];
    const heads = data[0];
    const headErr = [];
    for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
      const row = data[rowIndex];
      row.forEach((cell, colIndex) => {
        if (typeof cell !== expectedTypes[colIndex]) {
          headErr.push(`${heads[colIndex]}`);
          errors.push({
            head: heads[colIndex],
            rowNumber: rowIndex + 1,
            data: data[rowIndex][colIndex],
            type: expectedTypes[colIndex],
          });
        }
      });
    }
    setXlsxError(errors);
    return errors.length > 0 ? false : true;
  };

  const fetchFY = async () => {
    try {
      const response = await fetchFYYears();
      if (response?.data?.errorStatus === 200) {
        console.log(response?.data);
        setFyYears(response?.data?.fylist);
      } else if (response?.data?.errorStatus === 401) {
        toast.error(`Error: ${response?.data?.error}`);
      } else {
        toast.error(`There is some issue on ledgers not found.`);
      }
    } catch (error) {
      console.error("Error fetching FY years:", error);
      toast.error("An error occurred while fetching FY years.");
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setUploadDataStatus(false)
    setLoader(true);
    const file = xfile;
    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      setXLSXData(jsonData);
      const res = validateDataTypes(jsonData);
      // You can use setFormData to store file data or use it for any other purpose
      // setFormData({ ...formData, fileData: jsonData });

      // Example: uploading file data
      if (formData.fy_year !== "" && res) {
        console.log('financial yers ', formData.fy_year)
        const sendingData = {
          fy_year: formData.fy_year,
          file_data: jsonData,
          Company_ID: companyID,
          User_ID: JSON.parse(localStorage.getItem("userDetails"))._id,
        };
        const response = await uploadPreviousYearTB(sendingData);
        setLoader(false);
        if (response?.data?.errorStatus === 200) {
          setHeadErrors(response?.data?.headErrors);
          toast.success(`Previous Year Data Uploaded...`);
          // setUploadDataStatus(true);
          // now redirect to login page...
        } else if (response?.data?.errorStatus === 401) {
          return toast.error(`Error: ${response?.data?.error}`);
        } else {
          return toast.error(
            `There is some issue on entering client details try after sometime later.`
          );
        }
      }

      setLoader(false);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleOnChange = (e) => {
    e.preventDefault();
 
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectOnChange = (event, error, newValue) => {
    if (!newValue || newValue.key === "Please Select Correct Header") {
      setBtnDisableAll(true);
      alert("Please Select All Headers Correctly");
      return;
    }
    setBtnDisableAll(false);
    setUpdateLedgerHead((prev) => {
      const updatedEntries = { ...prev };
      const existingEntryKey = Object.keys(updatedEntries).find(
        (key) =>
          updatedEntries[key].Ledger_Name === error.Ledger_Name &&
          updatedEntries[key].Old_head === error.Head
      );

      if (existingEntryKey) {
        // Update existing entry
        updatedEntries[existingEntryKey] = {
          Head: newValue.key,
          Old_head: error.Head,
          Ledger_Name: error.Ledger_Name,
        };
      } else {
        // Add new entry
        updatedEntries[Object.keys(updatedEntries).length] = {
          Head: newValue.key,
          Old_head: error.Head,
          Ledger_Name: error.Ledger_Name,
        };
      }
      console.log(updatedEntries)
      return updatedEntries;
    });
  };

  const updateMyHeads = async () => {
    setLoaderAll(true)
    if(uniqueHeadErrors.length===Object.entries(updateLedgerHead).length){
    const updateDataHeadsToSend = (data, updates) => {
      return data.map((entry) => {
        for (let key in updates) {
          const update = updates[key];
          if (entry.Head === update.Old_head) {
            return { ...entry, Head: update.Head };
          }
        }
        return entry;
      });
    };
    const updatedDataNow = updateDataHeadsToSend(headErros, updateLedgerHead);
    const response = await uploadPreviousYearTBUpdated(updatedDataNow);
    if (response?.data?.errorStatus === 200) {
      setHeadErrors(response?.data?.headErrors);
      toast.success(`Previous Year Data Uploaded...`);
      setLoaderAll(false)
      setUploadDataStatus(true);
      // now redirect to login page...
    } else if (response?.data?.errorStatus === 401) {
      return toast.error(`Error: ${response?.data?.error}`);
    } else {
      return toast.error(
        `There is some issue on entering client details try after sometime later.`
      );
    }
  }else{
    alert("Please select the headers correctly")
  }
  setLoaderAll(false)
    setBtnDisableAll(true)
    setLoaderAll(false)
  };





  return (
    <div className="white_body">
      <div className="warning d-flex" style={{ marginBottom: "10px" }}>
        <p className="f-13 mt-5px">
          <span style={{ color: "red" }}>Please Note:</span> Add the Previous
          Year Trail Balance for the purpose of Previous Year Comparison in your
          Financials.
        </p>
        <a
          href="./Template.xlsx"
          className="f-13 margin-left-300px"
          download
          target="_blank"
        >
          Please Download the Template.{" "}
          <img src="./excel.svg" height="30" width="30" />{" "}
        </a>
      </div>
      <div className="d-flex gap-4 align-items-center justify-content-start">
        <div className="col-md-2 align-middle">
          <FormControl fullWidth size="small">
            <InputLabel id="select-label" sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" }
              }} >FY Year</InputLabel>
            <Select
            sx={{
              fontSize: "14px",
              "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" }
            }}
              labelId="select-label"
              value={formData.fy_year}
              label="FY Year"
              onChange={handleOnChange}
              required
              name='fy_year'
            >
              <MenuItem sx={{
                    fontSize: "14px",
                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" }
                  }} value="">
                <em>Select FY Year</em>
              </MenuItem>
              {fyYears.map((item, index) => (
                <MenuItem sx={{
                  fontSize: "14px",
                  "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" }
                }} value={item._id} key={index}>
                  {item.year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-md-3">
          <input
          inputProps={{style: {fontSize: "14px"}}}
            className="f-13"
            type="file"
            name="previous_year_tb"
            onChange={fileOnChange}
            onClick={(event)=> {
              setXlsxError([])
              event.target.value = null
         }}
          />
        </div>

        <div className="col-md-5 m-0 ">
          <Button
            onClick={handleFileUpload}
            variant="contained"
            sx={{ bgcolor: "#2DA2FB",width:"130px" }}
            color="primary"
            disableElevation
            disabled={ loader || btnDisble}
          >
            Upload
           {loader ? (<CircularProgress sx={{marginLeft:"10px"}} size="1rem" />) : ("")}
          </Button>
          <Button
            className="mx-4 my-5"
            onClick={updateMyHeads}
            variant="contained"
            sx={{ bgcolor: "#2DA2FB",width:"140px" }}
            color="primary"
            disableElevation
            disabled={btnDisbleAll||loaderAll}
          >
            Update All {loaderAll&&<CircularProgress sx={{marginLeft:"10px"}} size="1rem"/>}
          </Button>
        </div>
      </div>

      <div>
        <p style={{ color: "red", marginBottom: "5px", fontSize: "14px" }}>
          {xlsxError.length > 0 && "Error In uploaded XLSX File data"}
        </p>
        {xlsxError.length > 0 &&
          xlsxError.map((data) => (
            <p
              key={data}
              style={{ color: "red", marginBottom: "5px", fontSize: "14px" }}
            >
              Header: {data.head}, Data at row:{data.rowNumber} the data should
              be {data.type}
            </p>
          ))}
      </div>
      <div className="my-2">
        { headErros?.length > 0 && xlsxError?.length <= 0 ? (
          <TableContainer>
            <Table className="fa_register_table">
              <TableHead>
                <TableRow sx={{ border: "1px solid #e0e0e0" }}>
                  <TableCell
                    align="left"
                    sx={{
                      border: "1px solid black ",
                      color: "white",
                      padding: "2px 2px 2px 2px",
                    }}
                  >
                    Excel Head
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      border: "1px solid black",
                      color: "white",
                      padding: "2px 2px 2px 2px",
                    }}
                  >
                    Select Correct Head
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueHeadErrors?.map((_error, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      sx={{
                        border: "1px solid black",
                        padding: "0px 2px 0px 2px !important",
                      }}
                    >
                      {_error?.Head}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid black",
                        padding: "0px 2px 0px 2px !important",
                      }}
                      align="left"
                    >
                      <FormControl sx={{ width: "auto", padding: "4px" }}>
                        <Autocomplete
                          sx={{ width: '300px' }}
                          options={Object.entries(MasterHeadForPreviousDB).map(([key, value]) => ({ key, value }))}
                          getOptionLabel={(option) => option.value}
                          onChange={(event, newValue) => handleSelectOnChange(event, _error, newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="medium"
                              placeholder="Please Select Correct Header"
                            />
                          )}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          loader &&
          xlsxError.length <= 0 && (
            <div>
              {" "}
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default PreviousYearTB;
