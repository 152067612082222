export const MasterHeadForPreviousDB = {
  "Share Capital": "Share Capital",
  "Reserves and Surplus": "Reserves and Surplus",
  "Money received against share Warrants":
  "Money received against share Warrants",
  "Long-term Borrowings": "Long-term Borrowings",
  "Deferred tax Liabilities (Net)": "Deferred tax Liabilities (Net)",
  "Other Long term Liablities": "Other Long term Liablities",
  "Long Term Provisions": "Long Term Provisions",
  "Short-term borrowings": "Short-term borrowings",
  "Trade payables": "Trade payables",
  "Cash and Cash Equivalents": "Cash and Cash Equivalents",
  "Other Current Liabilities": "Other Current Liabilities",
  "Short Term Provisions": "Short Term Provisions",
  "Short Term Loans and Advances": "Short Term Loans and Advances",
  "Trade Receivables": "Trade Receivables",
  "Property, Plant and Equipment": "Property, Plant and Equipment",
  "Intangible assets": "Intangible assets",
  "Capital work-in-progress": "Capital work-in-progress",
  "Intangible assets under development": "Intangible assets under development",
  "Non-current investments": "Non-current investments",
  "Deffered Tax Assets (Net)": "Deffered Tax Assets (Net)",
  "Long-term Loans and Advances": "Long-term Loans and Advances",
  "Other Non-Current Assets": "Other Non-Current Assets",
  "Revenue from Operations": "Revenue from Operations",
  "Other Income": "Other Income",
  "Cost of materials consumed": "Cost of materials consumed",
  "Purchases of Stock-in-Trade": "Purchases of Stock-in-Trade",
  "Changes in inventories of finished goods work-in-progress and Stockin-Trade":
  "Changes in inventories of finished goods work-in-progress and Stockin-Trade",
  "Employee benefits expense": "Employee benefits expense",
  "Financecosts": "Financecosts",
  "Depreciation & Amortisation Expenses":
  "Depreciation & Amortisation Expenses",
  "Other Expenses": "Other Expenses",
  "Exceptional items": "Exceptional items",
  "Extraordinary items": "Extraordinary items",
  "Current Income tax": "Current Income tax",
  "Deferred tax Exp/ (Income)": "Deferred tax Exp/ (Income)",
  "Profit/(loss)from discontinuing operations":
  "Profit/(loss)from discontinuing operations",
  "Tax expense of discontinuing operations":
  "Tax expense of discontinuing operations",
};
