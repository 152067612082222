import { Button } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  downloadXLSAPI,
  generateXLS,
  getClientDetails,
  getDRSAgingAPI,
  getFixedAssetsDataAPI,
  getUntaggedDataForTestAPI,
  registerClientDetails,
} from "../../../Utils/API_Axios";
import Sidebar from "../Sidebar";
import TopHeader from "../TopHeader";
import Trials from "../Trials";
import ClientDetails from "./ClientDetails";
import FARegister from "./FARegister";
import PreviousYearTB from "./PreviousYearTB";
import SelectClient from "./SelectClient";
import { useSelector, useDispatch } from "react-redux";
import { setForm, setPageNum } from "../../../store/slices/pageSlice";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Financials = () => {
  const [page, setPage] = useState(1);
  const [clientForm, setClientForm] = useState({});
  const [REQUIRED_ERROR, SET_REQUIRED_ERROR] = useState({});
  const [uploadDataStatus, setUploadDataStatus] = useState(false);
  const dispatch = useDispatch();
  const pageNum = useSelector((state) => state.pageSlice.page);
  const form = useSelector((state) => state.pageSlice.form);
  const [openModal, setOpenModal] = React.useState(false);

  const pageSerial = [
    {
      page: 1,
      title: "Select Client",
    },
    {
      page: 2,
      title: "Client Details",
    },
    {
      page: 3,
      title: "Previous Year TB",
    },
    {
      page: 4,
      title: "TB Tagging",
    },
    {
      page: 5,
      title: "Other Notes",
    },
  ];

  useEffect(() => {
    clientDetailsFromDB();
  }, []);

  const clientDetailsFromDB = async (company_id, fy) => {
    let user_id = JSON.parse(localStorage.getItem("userDetails"))._id;
    const response = await getClientDetails({
      loginId: user_id,
      company_id: company_id || form.company_id,
      fy: fy || form.fy,
    });

    if (response?.data?.clientDetails != null) {
      setClientForm({
        ...response.data.clientDetails,
        fy: fy || form.fy,
        submission_stage: pageNum,
      });
      dispatch(setForm({ form: clientForm }));

      setPage(pageNum);
    } else {
      setClientForm((prev) => ({
        fy: form.fy,
        company_id: form.company_id,
        submission_stage: page,
      }));
      dispatch(setForm({ form: clientForm }));

      setPage(pageNum);
    }
  };

  const changePageAndContent = (id) => {
    setPage(id);
    setClientForm((prev) => ({ ...prev, submission_stage: id }));
    const cliForm = { ...clientForm, submission_stage: id };
    dispatch(setForm({ form: cliForm }));
    dispatch(setPageNum({ page: id }));
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const formatClientForm = (form) => {
    const formattedForm = {};

    for (const key in form) {
      if (form.hasOwnProperty(key) && typeof form[key] === "string") {
        formattedForm[key] = capitalizeFirstLetter(form[key]);
      } else {
        formattedForm[key] = form[key];
      }
    }

    return formattedForm;
  };

  const handlePrevious = () => {
    changePageAndContent(page - 1);
  };

  const getUntaggedDataForTest = async (_data) => {
    const Updated = getUntaggedDataForTestAPI(_data);
    console.log(Updated);
  };
  const getFixedAssetData = async (_data) => {
    const Updated = getFixedAssetsDataAPI(_data);
    console.log(Updated);
  };
  const isCompleted = useSelector(
    (state) => state.counter.completionPercentage
  );
  const handleNext = async (page) => {
    if (page === 1) {
      if (
        checkClientRequiredField("fy", clientForm, REQUIRED_ERROR) &&
        checkClientRequiredField("company_id", clientForm, REQUIRED_ERROR) &&
        clientForm.fy &&
        clientForm.company_id
      ) {
        // toast.success(`Tally Data Selected Successfully.`);
        await clientDetailsFromDB(clientForm.company_id, clientForm.fy);
        changePageAndContent(page + 1);
      } else {
        alert("Please Fill all the details.");
      }
    } else {
      if (page === 2) {
        if (
          checkClientRequiredField("client_name", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField("rupees_in", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField(
            "client_address",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "designatory_1",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "designatory_2",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField("signatory_1", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField("signatory_2", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField("a_udin", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField(
            "auditor_name",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "a_date_of_signing",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "a_designation",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField("firmname", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField("a_frn", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField(
            "a_membership_no",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "a_place_of_signing",
            clientForm,
            REQUIRED_ERROR
          )
        ) {
          if (await insertClientDetails(clientForm)) {
            let error = false;
            if (!error) {
              console.log(" chal ja bhai ..........");
              let user_id = JSON.parse(localStorage.getItem("userDetails"))._id;
              getDRSAgingAPI({
                Company_ID: clientForm.company_id,
                User_ID: user_id,
                fy_year: clientForm.fy,
              });
            }
            // toast.success(`Client Details Updated Successfully.`);
            changePageAndContent(page + 1);
          }
        }
      } else if (page === 3) {
        // toast.success(`Previous Year TB Updated Successfully.`);
        if (uploadDataStatus) {
          changePageAndContent(page + 1);
        } else {
          alert("Enter the data");
        }
      } else if (page === 4) {
        let error = false;
        if (!error) {
          let user_id = JSON.parse(localStorage.getItem("userDetails"))._id;
          getUntaggedDataForTest({
            company_id: clientForm.company_id,
            user_id: user_id,
            fy: clientForm.fy,
          });

          getFixedAssetData({
            Company_ID: clientForm.company_id,
            User_ID: user_id,
            fy_year: clientForm.fy,
          });
        }

        changePageAndContent(page + 1);
        // toast.success(`TB Tagging Completed Successfully.`);
        // if (Math.round(isCompleted) === 100) {
        //   changePageAndContent(page + 1);
        // } else {
        //   alert("Fill Head and Child of every Un-Tagged items until its 100% ");
        // }

        changePageAndContent(page + 1);
      } else if (page === 5) {
        // call the get-untagged-data-for-test
        // toast.success(`FA Register Updated Successfully.`);
        changePageAndContent(page + 1);
      } else if (page === 6) {
        // toast.success(`Related Party Trans Updated Successfully.`);
        changePageAndContent(page + 1);
      }
    }
  };

  const insertClientDetails = async (clientData) => {
    const payload = clientData;
    payload.user_id = JSON.parse(localStorage.getItem("userDetails"))._id;
    payload.fy = clientForm.fy;
    payload.company_id = clientForm.company_id;
    const payloadCapital = formatClientForm(payload);
    const response = await registerClientDetails(payloadCapital);
    if (response?.data?.errorStatus === 200) {
      return true;
    } else if (response?.data?.errorStatus === 401) {
      return toast.error(`Error: ${response?.data?.error}`);
    } else {
      return toast.error(
        `There is some issue on entering client details. Try again later.`
      );
    }
  };

  const handleSubmit = () => {
    console.log("clicked!!");
    setOpenModal(true)
  };
  const handleClose = () => {
    console.log("close!!");
    setOpenModal(false)
  };

  const generateXLX = () => {
    // console.log(clientForm)
    let fy = clientForm.fy
    let user_id = clientForm.user_id
    let company_id = clientForm.company_id

    const result = generateXLS({fy:fy,user_id:user_id,company_id:company_id})
    console.log(result)
    if(result?.data?.status == 'Success')
      window.alert(`File Generated Successfully`)
  }
  const downloadXLX = async() => {
    // console.log(clientForm)
    let fy = clientForm.fy
    let user_id = clientForm.user_id
    let company_id = clientForm.company_id

    const result = await downloadXLSAPI({fy:fy,user_id:user_id,company_id:company_id})
    const downloadUrl = result?.data?.url;

    if (downloadUrl) {
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'file.xlsx'); // Set the download attribute with a default file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM by removing the link
    }
  }

  const handleClientForm = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const { name, value } = e.target;

    if (name === "fy") {
      if (value !== clientForm[name]) {
        setClientForm((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setClientForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const checkClientRequiredField = (key, clientForm, REQUIRED_ERROR) => {
    SET_REQUIRED_ERROR({});
    if (
      clientForm.hasOwnProperty(key) &&
      clientForm[key] !== "" &&
      clientForm[key] !== null &&
      clientForm[key] !== undefined
    ) {
      return true;
    } else {
      SET_REQUIRED_ERROR({ ...REQUIRED_ERROR, [key]: key });
      // alert("Please Fill all the details.");
    }

    return false;
  };

  const handleStep = (step) => async () => {
    if (step < page) {
      if (step === 1) {
        if (
          checkClientRequiredField("client_name", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField("rupees_in", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField(
            "client_address",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "designatory_1",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "designatory_2",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField("signatory_1", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField("signatory_2", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField("a_udin", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField(
            "auditor_name",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "a_date_of_signing",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "a_designation",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField("firmname", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField("a_frn", clientForm, REQUIRED_ERROR) &&
          checkClientRequiredField(
            "a_membership_no",
            clientForm,
            REQUIRED_ERROR
          ) &&
          checkClientRequiredField(
            "a_place_of_signing",
            clientForm,
            REQUIRED_ERROR
          )
        ) {
          changePageAndContent(step);
        } else {
          return;
        }
      } else {
        changePageAndContent(step);
      }
    } else if (step > page) {
      for (let i = page; i <= step; i++) {
        if (i === 1) {
          if (
            checkClientRequiredField("fy", clientForm, REQUIRED_ERROR) &&
            checkClientRequiredField(
              "company_id",
              clientForm,
              REQUIRED_ERROR
            ) &&
            clientForm.fy &&
            clientForm.company_id
          ) {
            // toast.success(`Tally Data Selected Successfully.`);

            await clientDetailsFromDB(clientForm.company_id, clientForm.fy);
            changePageAndContent(1);
          } else {
            alert("Please Fill all the details.");
            break;
          }
        } else if (i === 2) {
          if (
            checkClientRequiredField(
              "client_name",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField("rupees_in", clientForm, REQUIRED_ERROR) &&
            checkClientRequiredField(
              "client_address",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField(
              "designatory_1",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField(
              "designatory_2",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField(
              "signatory_1",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField(
              "signatory_2",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField("a_udin", clientForm, REQUIRED_ERROR) &&
            checkClientRequiredField(
              "auditor_name",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField(
              "a_date_of_signing",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField(
              "a_designation",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField("firmname", clientForm, REQUIRED_ERROR) &&
            checkClientRequiredField("a_frn", clientForm, REQUIRED_ERROR) &&
            checkClientRequiredField(
              "a_membership_no",
              clientForm,
              REQUIRED_ERROR
            ) &&
            checkClientRequiredField(
              "a_place_of_signing",
              clientForm,
              REQUIRED_ERROR
            )
          ) {
            if (await insertClientDetails(clientForm)) {
              // toast.success(`Client Details Updated Successfully.`);
              changePageAndContent(2);
            }
          } else {
            changePageAndContent(2);
            break;
          }
        } else if (i === 3) {
          // toast.success(`Previous Year TB Updated Successfully.`);
          changePageAndContent(3);
        } else if (i === 4) {
          console.log("I am here in 4");
          if (uploadDataStatus) {
            changePageAndContent(4);
          } else {
            alert("Data missing in some step 3");
            changePageAndContent(3);
            break;
          }
        } else if (step === 5) {
          // toast.success(`FA Register Updated Successfully.`);
          changePageAndContent(5);
          // if (uploadDataStatus && Math.round(isCompleted) === 100) {
          //   changePageAndContent(5);
          // } else {
          //   alert(
          //     "Fill Head and Child of every Un-Tagged items until its 100% "
          //   );
          //   changePageAndContent(4);
          //   break;
          // }
        }
      }
    }
  };

  return (
    <div className="dashboard_main">
      <Sidebar />
      <main className="main_content">
        <TopHeader />
        <div className="sub_h">
          <div className="sub_header">
            <div className="cc">
              <Stepper activeStep={page - 1}>
                {pageSerial.map(({ title, page }) => (
                  <Step
                    key={page}
                    sx={{
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: "#2DA2FB", // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: "#2DA2FB", // circle color (ACTIVE)
                      },
                      fontSize: "18px !important",
                      "& .MuiStepLabel-root": {
                        ":hover": {
                          cursor: "pointer",
                        },
                      },
                    }}
                    onClick={handleStep(page)}
                  >
                    <StepLabel>{title}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <div className="prev_next">
                <Button
                  className={` ${page !== 1 ? "show" : "hide"}`}
                  onClick={handlePrevious}
                  variant="contained"
                  sx={{ bgcolor: "#383b3f", width: "100px" }}
                  color="primary"
                  disableElevation
                >
                  Previous
                </Button>
                <Button
                  type="submit"
                  className={`${page !== pageSerial.length ? "show" : "hide"}`}
                  sx={{ bgcolor: "#383b3f", width: "100px" }}
                  onClick={() => handleNext(page)}
                  variant="contained"
                  disableElevation
                >
                  Next
                </Button>
                <Button
                  className={`${page === pageSerial.length ? "show" : "hide"}`}
                  sx={{ bgcolor: "#383b3f", width: "100px" }}
                  onClick={handleSubmit}
                  variant="contained"
                  disableElevation
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>

        {page === 1 && (
          <SelectClient
            clientForm={clientForm}
            setClientForm={setClientForm}
            handleClientForm={handleClientForm}
            REQUIRED_ERROR={REQUIRED_ERROR}
          />
        )}
        {page === 2 && (
          <ClientDetails
            clientForm={clientForm}
            setClientForm={setClientForm}
            handleClientForm={handleClientForm}
            REQUIRED_ERROR={REQUIRED_ERROR}
          />
        )}
        {page === 3 && (
          <PreviousYearTB
            companyID={clientForm.company_id}
            setUploadDataStatus={setUploadDataStatus}
          />
        )}
        {page === 4 && <Trials companyID={clientForm.company_id} fy={clientForm.fy} />}
        {page === 5 && (
          <FARegister
            clientForm={clientForm}
            companyID={clientForm.company_id}
          />
        )}

<Dialog
          open={openModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="dialogBox"
        >
          <DialogTitle id="alert-dialog-title">
            {"Generate your financials"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{display:'flex','justifyContent':'center',alignItems:'center'}}>
             <Button onClick={generateXLX} 
              //sx={{ bgcolor: "#2DA2FB", width: "100px", color:'#ffffff', textAlign:'center' }}
              
              variant="contained"
              sx={{ bgcolor: "#383b3f", width: "100px" }}
              color="primary"
              > Generate </Button>
           
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" style={{display:'flex','justifyContent':'center',alignItems:'center',marginTop:'40px'}}>
            <Button onClick={downloadXLX} 
              //sx={{ bgcolor: "#2DA2FB", width: "100px", color:'#ffffff', textAlign:'center' }}
              
              variant="contained"
              sx={{ bgcolor: "#383b3f", width: "100px" }}
              color="primary"
              > Download Excel </Button>
                </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions> */}
        </Dialog>
      </main>
    </div>
  );
};

export default Financials;
