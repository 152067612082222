import React, { useCallback, useEffect, useMemo, useState, useImperativeHandle, forwardRef } from "react";
import { toast } from "react-toastify";
import {
  addTaggedTrail,
  fetchWithTempUntagged,
} from "../../../Utils/API_Axios";
import { toIndianCommaFormat } from "../../../Utils/math";
import { useFormData } from "./useFormData";
import {
  DefaultLedgerHeadDropDown1,
  DefaultLedgerHeadDropDown2,
  DefaultLedgerHeadDropDown3,
  DefaultLedgerHeadDropDown4,
  DefaultLedgerHeadDropDown5,
  DefaultLedgerHeadDropDown6,
  DefaultLedgerHeadMaster1,
  DefaultLedgerHeadMaster2,
  DefaultLedgerHeadMaster3,
  DefaultLedgerHeadMaster4,
  DefaultLedgerHeadMaster5,
  DefaultLedgerHeadMaster6,
} from "../../../Utils/DefaultLedgerHeadDropDown";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Autocomplete,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import { IconButton, Box } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

function flattenData(data, parent = null) {
  let result = [];
  data.forEach((item) => {
    if (item) {
      const newItem = {
        ...item,
        parentName: parent ? parent.name : null,
      };
      result.push(newItem);
      if (item.children && item.children.length > 0) {
        const children = flattenData(item.children, item);
        result = result.concat(children);
      }
    }
  });
  return result;
}
const masterDropdownMap = [
  { master: DefaultLedgerHeadMaster1, dropdown: DefaultLedgerHeadDropDown1 },
  { master: DefaultLedgerHeadMaster2, dropdown: DefaultLedgerHeadDropDown2 },
  { master: DefaultLedgerHeadMaster3, dropdown: DefaultLedgerHeadDropDown3 },
  { master: DefaultLedgerHeadMaster4, dropdown: DefaultLedgerHeadDropDown4 },
  { master: DefaultLedgerHeadMaster5, dropdown: DefaultLedgerHeadDropDown5 },
  { master: DefaultLedgerHeadMaster6, dropdown: DefaultLedgerHeadDropDown6 },
];

// Initialize cache outside of the function to persist across renders
const cache = new Map();

const findDropdownOptions = (parent) => {
  if (cache.has(parent)) {
    return cache.get(parent);
  }

  for (let map of masterDropdownMap) {
    if (map.master.includes(parent)) {
      cache.set(parent, map.dropdown);
      return map.dropdown;
    }
  }

  cache.set(parent, null);
  return null;
};

const DropdownOrInput = React.memo(({ ledger, item, handleOnChange }) => {
  const dropdownOptions = useMemo(
    () => findDropdownOptions(ledger.primaryGroup),
    [ledger.primaryGroup]
  );

  const handleDropdownChange = useCallback(
    (e) => {

      handleOnChange(
        ledger.ledgerId,
        "head",
        e.target.innerText,
        ledger.opening_balance,
        ledger.debit,
        ledger.credit,
        ledger.closing_balance,
        ledger.name,
        item.name,
        ledger.masterHead
      );
    },
    [handleOnChange, ledger, item]
  );

  const handleInputBlur = useCallback(
    (e) => {
      handleOnChange(
        ledger.ledgerId,
        "head",
        e.target.value,
        ledger.opening_balance,
        ledger.debit,
        ledger.credit,
        ledger.closing_balance,
        ledger.name,
        item.name,
        ledger.masterHead
      );
    },
    [handleOnChange, ledger, item]
  );

  return dropdownOptions ? (
    <Autocomplete
      onChange={(e) =>
        handleDropdownChange(e)
      }
      options={dropdownOptions}
      // value={ledger.head}
      // getOptionLabel={(option) => option}

      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  ) : (
    <TextField fullWidth type="text" onBlur={handleInputBlur} />
  );
});

const LedgerRow = React.memo(
  ({ ledger, item, handleOnChange, filledLedgers, padding }) => {
   const handleInputChange= useCallback ((e)=>handleOnChange(
      ledger.ledgerId,
      "child",
      e.target.value,
      ledger.opening_balance,
      ledger.debit,
      ledger.credit,
      ledger.closing_balance,
      ledger.name,
      item.name,
      ledger.masterHead

    ),[handleOnChange, ledger, item])
    return (
      <TableRow key={ledger.ledgerId}>
        <TableCell
          sx={{ padding: `2px 2px 2px ${padding + 10}px` }}
          width={480}
        >
          {ledger.name}
        </TableCell>
        <TableCell
          sx={{ padding: "2px 2px 2px 4px " }}
          className="numeric"
          width={100}
        >
          {toIndianCommaFormat(ledger.opening_balance) || ""}
        </TableCell>
        <TableCell
          sx={{ padding: "2px 2px 2px 4px " }}
          className="numeric"
          width={100}
        >
          {toIndianCommaFormat(ledger.debit) || ""}
        </TableCell>
        <TableCell
          sx={{ padding: "2px 2px 2px 4px " }}
          className="numeric"
          width={100}
        >
          {toIndianCommaFormat(ledger.credit) || ""}
        </TableCell>
        <TableCell
          sx={{ padding: "2px 2px 2px 4px " }}
          className="numeric"
          width={100}
        >
          {toIndianCommaFormat(ledger.closing_balance) || ""}
        </TableCell>
        <TableCell sx={{ padding: "2px 2px 3px 4px " }} width={360}>
          <DropdownOrInput
            ledger={ledger  }
            item={item}
            handleOnChange={handleOnChange}
          />
        </TableCell>
        <TableCell sx={{ padding: "2px 2px 2px 4px " }} width={360}>
        <TextField
            fullWidth
            type="text"
            defaultValue={ledger.child}
            // value={ledger.child}
            onBlur={(e) =>
              handleInputChange(e)
            }
          />


        </TableCell>
      </TableRow>
    );
  }
);

const RenderRows = React.memo(
  ({
    filledChildren = [],
    handleOnChange,
    filledLedgers = [],
    data = [],
    padding = 10,
    toggle,
    level = 0,
  }) => {
    const [openRowIndex, setOpenRowIndex] = useState({});
    const [loadingIndex, setLoadingIndex] = useState(null);

    const toggleRow = useCallback(
      (index) => {
        setLoadingIndex(index);
        setTimeout(() => {
          setOpenRowIndex((prevOpenRows) => ({
            ...prevOpenRows,
            [index]: !prevOpenRows[index],
          }));
         
          setLoadingIndex(null);
        }, 0); 
      },
      []
    );
    useEffect(() => {
      if (toggle) {
        const newOpenRowIndex = {};
        data.forEach((item, index) => {
          if (item?.children?.length || item?.ledger?.length) {
            newOpenRowIndex[index] = true;
          }
        });
        setOpenRowIndex(newOpenRowIndex)
      } else {
        setOpenRowIndex({});
      }
    }, [toggle, data]); 

    return (
      <>
       
        {data.map((item, index) =>
          item?.name &&
          !filledChildren[item.name] &&
          (!item.hasOwnProperty('isAnyUntagged') ||
            item?.isAnyUntagged === true) ? (
            <React.Fragment key={`${item.name}-${index}`}>
              {!filledChildren[item.name] && (
                <>
                  <TableRow
                    sx={{ border: '1px solid #2f2f2f' }}
                    id={`parent-${item.name}-${index}`}
                  >
                    <TableCell
                      onClick={() => toggleRow(index)}
                      sx={
                        level === 0
                          ? {
                              backgroundColor: '#2DA2FB',
                              color: '#fff',
                              padding: `2px 0px 2px 2px`,
                              cursor: 'pointer',
                            }
                          : {
                              backgroundColor: '#2DA2FB',
                              color: '#fff',
                              padding: `0px 0px 0px ${padding}px`,
                              cursor: 'pointer',
                            }
                      }
                    >
                      {level === 0 && (
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          sx={{ color: 'white', padding: '0px', margin: '0px' }}
                        >
                          {openRowIndex[index] ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      )}
                      {item.name}
                      {loadingIndex === index && (
                        <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loadingIndex === index}
                       >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                  )}    
                    </TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        backgroundColor: '#2DA2FB',
                        color: '#fff',
                        padding: `2px 0px 2px ${padding}px`,
                      }}
                    ></TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        backgroundColor: '#2DA2FB',
                        color: '#fff',
                        padding: `2px 0px 2px ${padding}px`,
                      }}
                    ></TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        backgroundColor: '#2DA2FB',
                        color: '#fff',
                        padding: `2px 0px 2px ${padding}px`,
                      }}
                    ></TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        backgroundColor: '#2DA2FB',
                        color: '#fff',
                        padding: `2px 0px 2px ${padding}px`,
                      }}
                    ></TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        backgroundColor: '#2DA2FB',
                        color: '#fff',
                        padding: `2px 0px 2px ${padding}px`,
                      }}
                    ></TableCell>
                    <TableCell
                      size="small"
                      sx={{
                        backgroundColor: '#2DA2FB',
                        color: '#fff',
                        padding: `2px 0px 2px ${padding}px`,
                      }}
                    ></TableCell>
                  </TableRow>
                  {level === 0 ? (
                    <>
                      {openRowIndex[index] && (
                        <>
                          {item.children && (
                            <RenderRows
                              filledChildren={filledChildren}
                              handleOnChange={handleOnChange}
                              filledLedgers={filledLedgers}
                              data={item.children}
                              padding={padding + 20}
                              level={level + 1} // increment the level prop for the next recursion
                            />
                          )}
                          {item.ledger &&
                            item.ledger.map(
                              (ledger) =>
                                !filledLedgers[ledger.ledgerId] &&
                                (!ledger.head || !ledger.child) && (
                                  <LedgerRow
                                    key={ledger.ledgerId}
                                    ledger={ledger}
                                    item={item}
                                    handleOnChange={handleOnChange}
                                    filledLedgers={filledLedgers}
                                    padding={padding + 10}
                                  />
                                )
                            )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {item.children && (
                        <RenderRows
                          filledChildren={filledChildren}
                          handleOnChange={handleOnChange}
                          filledLedgers={filledLedgers}
                          data={item.children}
                          padding={padding + 10}
                          level={level + 1} // increment the level prop for the next recursion
                        />
                      )}
                      {item.ledger &&
                        item.ledger.map(
                          (ledger) =>
                            !filledLedgers[ledger.ledgerId] &&
                            (!ledger.head || !ledger.child) && (
                              <LedgerRow
                                key={ledger.ledgerId}
                                ledger={ledger}
                                item={item}
                                handleOnChange={handleOnChange}
                                filledLedgers={filledLedgers}
                                padding={padding}
                              />
                            )
                        )}
                    </>
                  )}
                </>
              )}
            </React.Fragment>
          ) : null
        )}
      </>
    );
  },
  // (p, n) => {
  //   return (
  //     p.filledChildren.length === n.filledChildren.length &&
  //     p.filledLedgers.length === n.filledLedgers.length &&
  //     p.data.length === n.data.length
  //   );
  // }
);

const Untagged = forwardRef((props, ref) => {
  const { companyID,toggle,settotalUnLedgers } = props;
  const [jsonData, setJsonData] = useState([]);
  const [filledChildren, setFilledChildren] = useState({});
  const [formData, setFormData] = useState([]);
  const [filledLedgers, setFilledLedgers] = useState({});
  const flattenedData = useMemo(() => flattenData(jsonData), [jsonData]);

  const checkAndHideParent = useCallback(
    (parentName) => {
      if (!parentName) return;
      const childrenOfParent = flattenedData.find(
        (item) => item.name === parentName
      );

      const allFilled = childrenOfParent?.ledger?.every((child) => {
        const formEntry = formData.find(
          (formItem) => formItem.id === child.ledgerId
        );
        return formEntry && formEntry.head && formEntry.child;
      });

      if (allFilled) {
        setFilledChildren((prev) => ({ ...prev, [parentName]: true }));
      }
    },
    [flattenedData, formData]
  );
  const updateHiddenRows = useCallback((data, actualFilledChildren) => {
    let filledChildren = { ...actualFilledChildren };
    const checkAndHide = (node) => {
      if (!node || !node.children) return;
      if (filledChildren?.[node?.name]) return;

      node.children.forEach((child) => {
        if (child) {
          checkAndHide(child);
        }
      });
      const areAllChildrenFilled = node.children.every(
        (child) => child === null || (child.name && filledChildren[child.name])
      );

      if (areAllChildrenFilled && node.name) {
        const areLedgersFilled = node.ledger.every(
          (ledger) =>
            (ledger !== null && ledger.head !== "") || ledger.child !== ""
        );

        filledChildren[node.name] = areLedgersFilled;
      }
    };

    data.forEach((node) => {
      if (node) {
        checkAndHide(node);
      }
    });
    setFilledChildren((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(filledChildren)) {
        return filledChildren;
      }
      return prev;
    });
  }, []);
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      handleSubmit()
    }
  }));

  useEffect(() => {
    if (jsonData && filledChildren) {
      updateHiddenRows(jsonData, filledChildren);
    }
  }, [jsonData, filledChildren, updateHiddenRows]);

  useEffect(() => {
    const currentFilledLedgers = { ...filledLedgers };
    formData.forEach(({ id, head, child }) => {
      if (
        typeof head === "string" &&
        typeof child === "string" &&
        head !== "" &&
        child !== ""
      ) {
        currentFilledLedgers[id] = true;
      }
    });
    setFilledLedgers(currentFilledLedgers);
    
  }, [formData,flattenedData]);

  const { handleOnChange } = useFormData(
    formData,
    setFormData,
    flattenedData,
    checkAndHideParent
  );
  const [loading,setLoading]=useState(false)
  const fetchData = useCallback(
    async (companyID) => {
      try {
        setLoading(true)
        const response = await fetchWithTempUntagged({ companyID });
        if (response?.data?.errorStatus === 200) {
          setJsonData(response?.data?.data);
          const { totalLedgers } = countLedgers(response?.data?.data);
          settotalUnLedgers(totalLedgers)
          console.log("totalLedgers",totalLedgers);
        } else if (response?.data?.errorStatus === 401) {
          toast.error(`Error: ${response?.data?.error}`);
        } else {
          toast.error(
            `There is some issue on fetching Trail unTagged not found.`
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }finally{
        setLoading(false)
      }
    },
    [companyID]
  );

  useEffect(() => {
    fetchData(companyID);
  }, [companyID]);

  useEffect(() => {
    formData.forEach((f) => {
      checkAndHideParent(f.parentName);
    });
  }, [formData, checkAndHideParent]);
// Function to recursively count the total ledgers and filled ledgers
const countLedgers = (data) => {
  let totalLedgers = 0;
  const traverse = (items) => {
    items?.forEach((item) => {
      // Check if the item meets the condition before processing the ledger
      if (!item?.hasOwnProperty('isAnyUntagged') || item.isAnyUntagged === true) {
        if (item?.ledger) {
          item?.ledger.forEach((ledger) => {
            if(!ledger.isTagged)
            totalLedgers++;
          });
        }
      }

      if (item.children) {
        traverse(item.children);
      }
    });
  };

  traverse(data);
  return {totalLedgers};
};



  const handleSubmit = async () => {
    try {
      const userID = JSON.parse(localStorage.getItem("userDetails"))._id;
      const response = await addTaggedTrail({ formData, companyID, userID });
      if (response?.data?.errorStatus === 200) {
    

        toast.success(`Data Updated Successfully....`);
      } else if (response?.data?.errorStatus === 400) {
        toast.warning(`Message: ${response?.data?.message}`);
      } else if (response?.data?.errorStatus === 401) {
        toast.error(`Error: ${response?.data?.error}`);
      } else {
        toast.error(`There is some issue try after sometime later.`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      fetchData(companyID);
    }
  };
    
  const RenderRowsM = useMemo(() => {
    return (
      <RenderRows
        filledChildren={filledChildren}
        handleOnChange={handleOnChange}
        filledLedgers={filledLedgers}
        data={jsonData}
        level={0}
        toggle={toggle}
      />
    );
  }, [filledChildren, handleOnChange, filledLedgers, jsonData]);
  return (
    <div className="tab_data" style={{height:"500px",overflow:"auto"}}>
      <div className="w-50">
        {/* <Button
          className=""
          variant="contained"
          disableElevation
          sx={{ bgcolor: "#2DA2FB", width: "8rem" }}
          onClick={handleSubmit}
        >
          Update
        </Button> */}
      </div>
      <div className="tableTrail">
        <Table stickyHeader sx={{ backgroundColor: "#fff" }} >
          <TableHead sx={{ border: "1px solid #2f2f2f" }}>
            <TableRow>
              <TableCell
                sx={{ padding: "2px 2px 2px 4px",width: "50vw",backgroundColor:"#6c757d",color:"white"   }}
                scope="col"
                className="f-13"
              >
                Particulars
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px ",width: "12vw",backgroundColor:"#6c757d",color:"white" }}
                scope="col"
                className="f-13"
              >
                Open Bal
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px",width: "10vw",backgroundColor:"#6c757d",color:"white"   }}
                scope="col"
                className="f-13"
              >
                Debit
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px",width: "10vw",backgroundColor:"#6c757d",color:"white"   }}
                scope="col"
                className="f-13"
              >
                Credit
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px ",width: "12vw",backgroundColor:"#6c757d",color:"white" }}
                scope="col"
                className="f-13"
              >
                Close Bal
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px ",width: "28vw",backgroundColor:"#6c757d",color:"white" }}
                scope="col"
                className="f-13"
              >
                Head
              </TableCell>
              <TableCell
                sx={{ padding: "2px 0px 2px 2px ",width: "28vw",backgroundColor:"#6c757d",color:"white" }}
                scope="col"
                className="f-13"
              >
                Child
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="myTable">{RenderRowsM}</TableBody>
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </Table>
      </div>
    </div>
  );
});

export default Untagged;
