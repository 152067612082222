import { Menu, MenuItem, Button, Box, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import FixedAsset from "./othernotes/FixedAsset";
import RelatedPartyTrans from "./RelatedPartyTrans";
import ShareCapital from "./othernotes/ShareCapital";

const FARegister = ({ clientForm, companyID }) => {
  const [currentTab, setCurrentTab] = useState(0);
  // const changeCurrentTab = (id,btn) => {

  //   setCurrentTab(id);
  // };

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // console.log(currentTab)

  return (
    // <div className="white_body">
    //   <div className="other_notes_container">
    //   <div className="other_notes_btn">
    //     <Button

    //       autoFocus
    //       variant="outlined"
    //       disableElevation
    //       disableRipple
    //       className={currentTab === 1 ? "tabactive" : ""}
    //       onClick={() => changeCurrentTab(1)}
    //     >
    //       Share Capital
    //     </Button>
    //     <Button
    //       variant="outlined"
    //       disableElevation
    //       disableRipple
    //       className={currentTab === 2 ? "tabactive" : ""}
    //       onClick={() => changeCurrentTab(2)}
    //     >
    //       Fixed Asset (FA)
    //     </Button>
    //     <Button
    //       variant="outlined"
    //       disableElevation
    //       disableRipple
    //       className={currentTab === 3 ? "tabactive" : ""}
    //       onClick={() => changeCurrentTab(3)}
    //     >
    //       Related Party Trans
    //     </Button>
    //   </div>
    //   <div
    //     className={`tab_data ${currentTab == "1" ? "active" : ""}`}
    //     style={{
    //       display: currentTab == "1" ? "block" : "none",
    //     }}
    //   >

    //     <ShareCapital clientForm={clientForm}  />
    //   </div>
    //   <div
    //     className={`tab_data ${currentTab == "2" ? "active" : ""}`}
    //     style={{
    //       display: currentTab == "2" ? "block" : "none",
    //     }}
    //   >

    //     <FixedAsset/>
    //   </div>
    //   <div
    //     className={`tab_data ${currentTab == "3" ? "active" : ""}`}
    //     style={{
    //       display: currentTab == "3" ? "block" : "none",
    //     }}
    //   >

    //     <RelatedPartyTrans/>
    //   </div>

    //   </div>
    // </div>
    <div className="white_body">
<div className="tab_container" style={{position:"relative"}}>
<Box sx={{ display: 'flex', position: 'sticky', top: '85px', zIndex: 1000, backgroundColor: 'white',overflow:"hidden" }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="trials tabs"
            sx={{
              ".Mui-selected": { color: `#2DA2FB` },
              ".MuiTabs-indicator": { bgcolor: "#2DA2FB" },
              borderBottom: 1,
              borderColor: "divider",
              width: "400px",
            }}
          >
            <Tab label="Share Capital" sx={{ textTransform: "capitalize" }} />
            <Tab label="Fixed Asset" sx={{ textTransform: "capitalize" }} />
            {/* <Tab
              label="Related Party Trans"
              sx={{ textTransform: "capitalize" }}
            /> */}
          </Tabs>
        </Box>
        {currentTab === 0 && (
          <Box sx={{ margin: "20px 0" }}>
            <ShareCapital
              clientForm={clientForm}
              setCurrentTab={setCurrentTab}
            />
          </Box>
        )}
        {currentTab === 1 && (
          <Box sx={{ margin: "20px 0" }}>
            <FixedAsset clientForm={clientForm} />
          </Box>
        )}
        {currentTab === 2 && (
          <Box sx={{ margin: "20px 0" }}>
            <RelatedPartyTrans />
          </Box>
        )}
      </div>
    </div>
  );
};

export default FARegister;
