import React, { useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PUBLIC_URL } from "../../App";

import { loginUser } from "../../Utils/API_Axios";
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email_or_mobile: "",
    password: "",
  });

  const emailOrMobileRef = useRef(null);
  const passwordRef = useRef(null);

  // Handle the key press event to move to the next input or submit the form
  const handleKeyPress = (event, nextRef) => {
    if (event.key === 'Enter') {
      if (nextRef) {
        nextRef.current.focus();
      } else {
        handleFormSubmit();
      }
    }
  };

  const [hidePass, setHidePass] = useState(true);

  const onChangeHandle = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async () => {
    // try login now
    const response = await loginUser(formData);
    if (response?.data?.errorStatus === 200) {
      localStorage.setItem('userToken', response?.data?.token)
      localStorage.setItem('userDetails', JSON.stringify(response?.data?.user))
      navigate("/financials");
      // toast.success(
      //   `Login Successfull, You can now redirecting to dashboard!!`,
      //   {
      //     onClose: () => {
      //       navigate("/financials");
      //     },
      //   }
      // );


      // empty the form fields
      setFormData({
        email_or_mobile: "",
        password: "",
      });

      // now redirect to login page...
    } else if (response?.data?.errorStatus === 402) {
      return toast.warning(`Message: ${response?.data?.message}`, {
        onClose: () => {
          navigate("/auth/verfiy-token");
        },
      });
    }
    else if (response?.data?.errorStatus === 401) {
      return toast.error(`Error: ${response?.data?.error}`);
    } else {
      return toast.error(
        `There is some issue on login try after sometime later.`
      );
    }
  };

  return (
    <div style={{ maxHeight: "100vh", height: "100%", overflow: "hidden",fontSize:"16px"
     }}>
      <div  style={{fontSize:"16px"}}className="row">
        <div className="col-md-4 login_img_box">
          <img
            src={`${PUBLIC_URL}/images/login_img.png`}
            alt=""
            className="login_img"
          />
          <h4 className="text-white lilita">Making Finance Intelligent Artificially</h4>
          <p className="text-white lilita">because just an AI is not enough</p>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5 login_form_box">
          <img
            src={`${PUBLIC_URL}/images/logo.png`}
            alt=""
            style={{ height: "15vh" }}
          />
          <div style={{ width: "70%" }}>
            <form >
              <div className="mb-3">
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    label="Email or Mobile No"
                    variant="outlined"
                    type="text"
                    id="email_or_mobile"
                    onChange={onChangeHandle}
                    value={formData.email_or_mobile}
                    name="email_or_mobile"
                    sx={{ mt: 3 }}
                    inputRef={emailOrMobileRef}
                    onKeyDown={(e) => handleKeyPress(e, passwordRef)}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <div className="d-flex password_div">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      id="password"
                      label="Password"
                      variant="outlined"
                      type={hidePass ? 'password' : 'text'}
                      onChange={onChangeHandle}
                      value={formData.password}
                      name="password"
                      inputRef={passwordRef}
                      onKeyDown={(e) => handleKeyPress(e, null)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { setHidePass(!hidePass) }}
                            edge="end"
                          >
                            {hidePass ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
              <p className="my-3 text-end">
                <NavLink to="/auth/forgot-pass" className='rm_link'>Forgot your Password?</NavLink>
              </p>
              <Button
                fullWidth
                onClick={handleFormSubmit}
                variant="contained"
                className="btn btn-dark btn-block w-100  line-height-2"
                disableElevation
                disableFocusRipple
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#393939"
                  }
                }}
              >
                Sign In
              </Button>
            </form>
            <div className="or-line">
              <hr className="line" />
              <span className="or-text">Or</span>
              <hr className="line" />
            </div>
            <p className="my-3 text-center rm_link">
              Are you new??  &nbsp;
              <NavLink to="/auth/register" className='rm_link'>
                Create An Account
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
