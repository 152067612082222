import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import SearchIcon from "@mui/icons-material/Search"
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { fetchFYYears, getCompanysDetails } from "../../../Utils/API_Axios"

const SelectClient = ({
  clientForm,
  handleClientForm,
  REQUIRED_ERROR,
  setClientForm,
}) => {
  const [fyYears,setFyYears] = useState([])
  const [companiesList,setCompaniesList] = useState([])
  const [searchQuery,setSearchQuery] = useState("")



  const filteredCompanies = companiesList?.filter((company) =>
    company.name.toLowerCase().includes(searchQuery.toLowerCase())
  )



  useEffect(() => {
    fetchFY()
    fetchCompanies()
  },[searchQuery])



  const fetchFY = async () => {
    const response = await fetchFYYears()


    if (response?.data?.errorStatus === 200) {
      setFyYears(response?.data?.fylist)


    } else if (response?.data?.errorStatus === 401) {
      toast.error(`Error: ${response?.data?.error}`)


    } else {
      toast.error(`There is some issue on ledgers not found.`)


    }
  }



  const fetchCompanies = async () => {
    const user_id = JSON.parse(localStorage.getItem("userDetails"))._id
    const response = await getCompanysDetails({ user_id })
    if (response?.data?.errorStatus === 200) {
      setCompaniesList(response?.data?.companies)


    } else if (response?.data?.errorStatus === 401) {
      toast.error(`Error: ${response?.data?.error}`)


    } else {
      toast.error(`There is some issue on ledgers not found.`)


    }
  }



  const setcompanyName = (id) => {
    handleClientForm({ target: { name: "company_id",value: id } })
  }

  return (
    <div className="white_body">

      <div className="row">
        <div className="col-md-4">
        <FormControl size="small" fullWidth>
          <TextField
            error={REQUIRED_ERROR.company_id}
            InputLabelProps={{
              sx: {
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" }
              }
            }}
            label="Search companies..."
            fullWidth
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </FormControl>
        </div>


        <div className="col-md-2">
              <FormControl size="small" fullWidth>
                <InputLabel sx={{
                fontSize: "14px",
                "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" }
              }}>FY Year</InputLabel>
                <Select
                sx={{
                  fontSize: "14px",
                  "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" }
                }}
                  name="fy"
                  required
                  label="FY Year"
                  error={REQUIRED_ERROR.fy}
                  value={clientForm.fy}
                  onChange={(e) => {
                    handleClientForm(e)
                  }}
                >
                  <MenuItem 
                  sx={{
                    fontSize: "14px",
                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" }
                  }} value="">
                    <em>Select FY</em>
                  </MenuItem>
                  {fyYears.map((item,index) => (
                    <MenuItem sx={{
                      fontSize: "14px",
                      "&.MuiOutlinedInput-notchedOutline": { fontSize: "14px" }
                    }} value={item._id} key={index}>
                      {item.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </div>
      </div>


      <div className="my-3 opacity-50 f-13">

          <>
            <CheckCircleIcon color="success" sx={{outline:"none"}} /> Selected: Tally Backup of{" "}
            {companiesList.find((item) => item._id === clientForm.company_id)?.name ||
              ""}
          </>

      </div>
      <div className="client_list">
        <label className="form-label f-13 font-bold" htmlFor="search_company">
          Please Select the Tally Backup Below
        </label>
        <div className="d-flex gap-4 ">
          {filteredCompanies?.map((company,index) => (
            <>
              <div
                className={`col-md-5 my-2 mx-2 text-left  cursor-pointer ${clientForm.company_id === company._id ? "_selected" : ""
                  }`}
                key={company._id}
                onClick={() => setcompanyName(company._id)}
              >
                <p className="p-2">
                  <span><img src='/images/dashboard_img/folder.svg' className="folder_svg" /> &nbsp;&nbsp; {++index}.  </span>
                  {company.name}
                </p>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  )

}
export default SelectClient;