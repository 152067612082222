import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  form:{}
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPageNum: (state,action) => {
   return { ...state, page:action.payload.page };
    },
    setForm: (state,action) => {
     return { ...state,form:action.payload.form}
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageNum, setForm } = pageSlice.actions;

export default pageSlice.reducer;
